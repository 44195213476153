import { useState } from "react";
import { NavLink } from "react-router-dom";
import React from "react";
import { Button, Grid } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "..//..//assets/UBSBILL.png";
import "../Navbar/Navbar.css";
import Contact from "../Screen/Contact/Contact";
import GeneralPOS from "../Screen/GeneralPOS/GeneralPOS";
import Home from "../Screen/Home/Home";
import Pricing from "../Screen/Pricing/Pricing";
import RestaurantPOS from "../Screen/RestaurantPOS/RestaurantPOS";
import UBSCart from "../Screen/UBScart/UBScart";

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);

  const toggleDrawer = () => setShowLinks(!showLinks);

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setShowLinks(true);
  };

  return (
    <div>
      <nav className="navbar">
        <NavLink
          to="/"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <a href="/Home">
              <img
                src={Logo}
                alt="Logo"
                style={{ height: "3rem", width: "auto" }}
              />
            </a>
          </Grid>
        </NavLink>

        <div className="nav-links-container">
          <ul className={showLinks ? "nav-links mobile open" : "nav-links"}>
            <li>
              <NavLink to="/" element={<Home />} onClick={toggleDrawer}>
                Home
              </NavLink>
            </li>
            <li className="dropdown">
              <NavLink to="#" onClick={handleDropdownClick}>
                Products <ArrowDropDownIcon />
              </NavLink>
              <div className="dropdown-content">
                <NavLink
                  to="/RestaurantPOS"
                  element={<RestaurantPOS />}
                  onClick={toggleDrawer}
                >
                  Restaurant POS
                </NavLink>
                <NavLink
                  to="/GeneralPOS"
                  element={<GeneralPOS />}
                  onClick={toggleDrawer}
                >
                  General POS
                </NavLink>
              </div>
            </li>
            <li>
              <NavLink
                to="/Pricing"
                element={<Pricing />}
                onClick={toggleDrawer}
              >
                Pricing
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/UBScart"
                element={<UBSCart />}
                onClick={toggleDrawer}
              >
                UBS Cart
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Contact"
                element={<Contact />}
                onClick={toggleDrawer}
              >
                Contact
              </NavLink>
            </li>
            <Hidden mdUp>
            <div style={{ textAlign: "left" }}>
              <a
                href="https://demo.ubsbill.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: "#03989e",
                    color: "white",
                    fontSize: "clamp(0.2rem, 0.1737rem + 2.1053vw, 0.8rem)",
                  }}
                >
                  login
                </Button>
              </a>
            </div>
            </Hidden>
          </ul>
        </div>
        <Hidden mdDown>
          <div style={{ textAlign: "center" }}>
            <a
              href="https://prod.ubsbill.com/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#03989e",
                  color: "white",
                  fontSize: "clamp(0.2rem, 0.1737rem + 2.1053vw, 0.8rem)",
                }}
              >
                login
              </Button>
            </a>
          </div>
        </Hidden>

        <Hidden mdUp>
          <button className="toggle-button" onClick={toggleDrawer}>
            {showLinks ? <CloseIcon /> : "☰"}
          </button>
        </Hidden>
      </nav>
    </div>
  );
};

export default Navbar;
