import { Typography, Button, Grid, Hidden } from "@mui/material";
import { Link } from 'react-router-dom'; 
import Image from '..//..//..//assets/retailpos.png'; 
import Imageslider from "./Subcompo/Imageslider";
import Subcompo from "./Subcompo/Subcompo";
import { Helmet } from "react-helmet";
const GeneralPOS = () => {
  return (
    <div>
       <Helmet>
        <title>GeneralPOS | UBS Bill</title>
      </Helmet>
    <Grid container spacing={2} sx={{ pt: 25 }}>
      <Hidden smUp >
        <Grid item xs={12} sm={6} md={6} lg={6} >
          <img src={Image} alt="Retail POS" style={{ width: "50%", height: "auto",marginTop:"-20%" }} />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <div style={{ padding: "0 25px",textAlign:"left" }}>
          <Typography style={{ marginBottom: 8 ,fontSize: "clamp(0.5rem, 0.8737rem + 2.1053vw, 2rem)",fontWeight:"550" }}>
            Retail POS System
          </Typography>
          <Typography variant="body1" color="textSecondary" style={{ marginBottom: 16,fontSize: "clamp(0.5rem, 0.3737rem + 2.1053vw, 1.2rem)" }}>
            The only all-in-one affordable retail POS system you'll ever need
          </Typography>
          <Link to="/Demo">
                      {" "}
                     
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginBottom:"30px",
                          marginTop: "10px",
                          backgroundColor: "#03989e",
                        }}
                      >
                        Book a Demo
                      </Button>
                    </Link>
        </div>
      </Grid>
      <Hidden smDown sx={{ pt: 25 }}>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: -20 }}>
          <img src={Image} alt="Retail POS" style={{ width: "50%", height: "auto" }} />
        </Grid>
      </Hidden>
    </Grid>

   <Imageslider/>
   <Subcompo/>
    </div>
  );
}

export default GeneralPOS;
