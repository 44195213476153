import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import CustomSnackbar from "./Customsnackbar";
import contact from "../../assets/contact.png";
import { InputLabel } from "@mui/material";
import axios from "axios";

function Demo() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneno: "",
    city: "",
    state: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phoneno: "",
    city: "",
    state: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    let errorMessage = "";

    switch (field) {
      case "phoneno":
        value = value.replace(/\D/g, "").slice(0, 10);
        errorMessage = !/^[6-9]\d{9}$/.test(value)
          ? "Please enter a valid 10-digit number"
          : "";
        break;

      case "email":
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        errorMessage = !emailRegex.test(value)
          ? "Please enter a valid email address"
          : "";
        break;
      default:
        value = value.replace(/[^a-zA-Z ]/g, "");
        break;
    }

    setErrors({ ...errors, [field]: errorMessage });
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
    .post(
      "https://api.ubsbill.com/api/request-for-enquiry-Form/register",
      formData
    )
    .then((response) => {
      console.log("form submitted", response.data);
      setSuccessSnackbarOpen(true);
      setFormData({
        name: "",
        email: "",
        phoneno: "",
        city: "",
        state: "",
      });
      setSubmitting(false);
    })
    .catch((error) => {
      console.error("Error posting data:", error);
      setOpenSnackbar(true); 
      setSubmitting(false);
    });
    setSubmitting(true);

    const requiredFields = ["name", "email", "phoneno", "city", "state"];
    const emptyFields = [];
    for (const field of requiredFields) {
      const fieldValue =
        typeof formData[field] === "string"
          ? formData[field].trim()
          : formData[field];
      if (!fieldValue) {
        emptyFields.push(field);
      }
    }
    if (emptyFields.length > 0) {
      setSnackbarMessage(
        `Please fill in the following fields: ${emptyFields.join(", ")}`
      );
      setOpenSnackbar(true);
      setSubmitting(false);
      return;
    }
    if (
      errors.name ||
      errors.email ||
      errors.phoneno ||
      errors.city ||
      errors.state
    ) {
      setSnackbarMessage("Please fix validation errors");
      setOpenSnackbar(true);
      setSubmitting(false);
      return;
    }

    
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setSuccessSnackbarOpen(false);
  };

  return (
    <div>
      <Grid container padding={"130px 20px 0px 20px"}>
        <Grid item xs={12} sm={12} md={6} lg={6} textAlign={"left"}>
          <Typography
            style={{ padding: "0px 10px 0px 10px", fontWeight: "550" }}
            fontWeight={"bold"}
            fontSize={"clamp(0.9rem, 0.2737rem + 2.1053vw, 1.4rem)"}
          >
            Book a free demo to explore our services and clarify queries.
            Contact us to schedule.
          </Typography>
          <br />
          <Grid style={{ padding: "0px 0px 0px 80px" }}>
            <img src={contact} alt="image" width="70%" height="70%" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div>
            <Grid>
              <Box sx={{ width: "100%", padding: "0px" }}>
                <Box mt={2} fontSize={"1rem"} padding={"5px 5px 5px 25px"}>
                  <Grid
                    container
                    rowGap={2}
                    columnSpacing={2}
                    justifyContent={"center"}
                    textAlign={"start"}
                  >
                    <Grid container spacing={3} sx={{ marginTop: "10px" }}>
                      <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <TextField
                          name="name"
                          fullWidth
                          value={formData.name}
                          onChange={handleChange("name")}
                          size="small"
                          placeholder="Full Name"
                          inputProps={{
                            minLength: 3,
                            maxLength: 50,
                            style: {
                              backgroundColor: "white",
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <TextField
                          fullWidth
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange("email")}
                          size="small"
                          InputProps={{
                            style: {
                              backgroundColor: "white",
                            },
                          }}
                          inputProps={{
                            minLength: 10,
                            maxLength: 50,
                          }}
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="phoneno">Phone No</InputLabel>
                        <TextField
                          fullWidth
                          name="phoneno"
                          value={formData.phoneno}
                          onChange={handleChange("phoneno")}
                          size="small"
                          placeholder="Phone No"
                          InputProps={{
                            style: {
                              backgroundColor: "white",
                              borderRadius: "5px",
                            },
                          }}
                          inputProps={{
                            minLength: 10,
                            maxLength: 10,
                          }}
                          error={!!errors.phoneno}
                          helperText={errors.phoneno}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="city">City</InputLabel>
                        <TextField
                          name="city"
                          fullWidth
                          value={formData.city}
                          onChange={handleChange("city")}
                          size="small"
                          placeholder="City"
                          inputProps={{
                            minLength: 3,
                            maxLength: 50,
                            style: {
                              backgroundColor: "white",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="state">State</InputLabel>
                        <TextField
                          fullWidth
                          name="state"
                          value={formData.state}
                          onChange={handleChange("state")}
                          size="small"
                          placeholder="State"
                          InputProps={{
                            style: {
                              backgroundColor: "white",
                              borderRadius: "5px",
                            },
                          }}
                          inputProps={{
                            minLength: 10,
                            maxLength: 20,
                          }}
                          error={!!errors.state}
                          helperText={errors.state}
                        />
                      </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-start">
                      <Button
                        sx={{
                          backgroundColor: "#00989E",
                          width: "10vw",
                          fontSize: "0.8rem",
                          color: "white",
                          padding: "10px 20px",
                          marginTop: "12px",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                          "&:hover": {
                            backgroundColor: "#03989e",
                          },
                        }}
                        onClick={handleSubmit}
                        disabled={submitting} 
                      >
                        {submitting ? ( 
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "SUBMIT"
                        )}
                      </Button>
                    </Grid>

                    {/* Loader */}
                    {submitting && (
                      <div
                        style={{
                          position: "fixed",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          background: "rgba(255, 255, 255, 0.8)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 9999,
                        }}
                      >
                        <CircularProgress color="primary" />
                      </div>
                    )}

                    {/* Snackbar */}
                    <CustomSnackbar
                      open={openSnackbar || successSnackbarOpen}
                      autoHideDuration={3000}
                      handleClose={handleCloseSnackbar}
                      message={
                        openSnackbar
                          ? snackbarMessage
                          : "Form Submitted Successfully!"
                      }
                      severity={openSnackbar ? "error" : "success"}
                    />
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Demo;
