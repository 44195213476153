import { Box, Grid, Typography, CardMedia } from "@mui/material";

import image1 from "..//..//..//..//assets/card1.png";
import image2 from "..//..//..//..//assets/card2.png";
import image3 from "..//..//..//..//assets/card3.png";
import image4 from "..//..//..//..//assets/card4.png";
import image5 from "..//..//..//..//assets/card5.png";

const card = [
  {
    pic: image1,
    alt: "image1",
    title: "Cash Register",
    description: "",
  },
  {
    pic: image2,
    alt: "image2",
    title: "Bill Printing",
    description: "",
  },
  {
    pic: image3,
    alt: "image3",
    title: "E-Bill",
    description: "",
  },
  {
    pic: image4,
    alt: "image4",
    title: "  Inventory Management",
    description: "",
  },
  {
    pic: image5,
    alt: "image5",
    title: "Vendor Management",
    description: "",
  },
];

const cards = () => {
  return (
    <div>
      <Grid
        container
        justifyContent={"space-around"}
        textAlign={"center"}
        sx={{ backgroundColor: "#F6F9FA", paddingY: "40px", marginY: "3rem" }}
      >
        <Grid item  xs={12} sm={6} md={6}lg={12}>
          <Typography padding={1}  style={{fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 2.5rem",fontWeight:"500"}}>
            Features
          </Typography>
          <Typography color={"#059A9F"} style={{fontSize: "clamp(0.5rem, 0.5737rem + 2.1053vw, 1.8rem)"}}>OUR SPECIAL FEATURES</Typography>
        </Grid>

        {card.map((title1, index) => (
          <Box
            key={index}
            margin={"1rem"}
            padding={"1rem 1rem"}
            sx={{
              backgroundColor: "white",
              borderRadius: "5px",
              width: { xs: "45%", md: "30%", sm: "45%" },
              marginX: "auto",
              marginBottom: "2rem",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
            height={"auto"}
          >
            <CardMedia
              component={"img"}
              sx={{
                objectFit: "cover",
                height: "15vmin",
                width: "15vmin",
                margin: "auto",
              }}
              title=""
              image={title1.pic}
            />
            <Typography
              variant="h1"
              fontSize={"clamp(0.5rem, 0.3737rem + 1.1053vw, 1.2rem)"}
              fontWeight={"550"}
              margin={"1rem 0"}
            >
              {title1.title}
            </Typography>
            <Typography fontSize={"2.8vmin"}>{title1.description}</Typography>
          </Box>
        ))}
      </Grid>
    </div>
  );
};

export default cards;
