import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";

const Bookademo = () => {
  return (
    <Grid
      container
      style={{
        backgroundColor:"#F6F9FA",
        padding:"30px"
      }}
    >
      <Grid item xs={12} lg={6}>
        <Typography
          variant="body1"
          style={{
            fontSize: "clamp(0.5rem, 0.3737rem + 2.0053vw, 1.8rem)",
            fontWeight: "500",
            textAlign: "left",
          }}
        >
          POS Restaurant is incredibly fast and reliable.
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: "#5C6574",
            fontSize: "clamp(0.2rem, 0.0737rem + 2.3053vw, 0.9rem)",
            textAlign: "left",
          }}
        >
          WE BRING A PERSONAL AND EFFECTIVE APPROACH TO EVERY PRODUCT, WHICH IS
          WHY OUR CLIENTS LOVE US AND WHY THEY KEEP COMING BACK.
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Link to="/Demo">
          {" "}
          <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: "10px",
              backgroundColor: "#03989e",
            }}
          >
            Book a Demo
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default Bookademo;
