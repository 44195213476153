import React from "react";
import { Box, Typography, Card, CardContent, Grid, useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from "@mui/material/Rating"; 

function Testimonials() {
  const testimonials = [
    {
      title: "- Durga Cafe",
      description:
        "UBS Bill provided us with the all in one ERP system to track, manage, automate, and complete our scheduled tasks with appropriate resources.",
    },
    {
      title: "- JW marriott",
      description:
        "UBS Bill provided us with the all in one ERP system to track, manage, automate, and complete our scheduled tasks with appropriate resources.",
    },
    {
      title: "- Hotel Radha Krishna",
      description:
        "UBS Bill provided us with the all in one ERP system to track, manage, automate, and complete our scheduled tasks with appropriate resources.",
    },
  ];

  const isMobile = useMediaQuery("(max-width:950px)");

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Box padding={"10px 30px 20px 30px"}>
        <Typography
          sx={{
            fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 2rem)",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "5%",
          }}
        >
          Testimonials
        </Typography>
        <Typography
          sx={{
            fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.2rem)",
            textAlign: "center",
            paddingTop: "10px",
          }}
        >
          Our Customers Have Something To Say About Us
        </Typography>
      </Box>

      {isMobile ? (
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <Grid item xs={12} sm={10} md={8} lg={6}>
                  <Card
                    sx={{
                      margin: "5%",
                      height: "100%",
                      padding: "0px",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Rating name="size-large" defaultValue={2} size="large" />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {testimonial.description}
                      </Typography>
                      <Typography
                        gutterBottom
                        sx={{
                          fontWeight: "bold",
                          marginTop: "2%",
                          fontSize: "clamp(1rem, 0.4737rem + 2.1053vw, 1.5rem)",
                          textAlign: "right",
                        }}
                      >
                        {testimonial.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          ))}
        </Slider>
      ) : (
        <Grid container spacing={5} padding={"1% 10% 2% 10%"}>
          {testimonials.map((testimonial, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              alignItems={"space-between"}
            >
              <Card
                sx={{
                  height: "100%",
                  padding: "0px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Rating name="size-large" defaultValue={2} size="large" />
                  <Typography variant="body2" color="textSecondary" component="p">
                    {testimonial.description}
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      marginTop: "2%",
                      fontSize: "clamp(0.8rem, 0.4737rem + 2.1053vw, 1rem)",
                      textAlign: "right",
                    }}
                  >
                    {testimonial.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}

export default Testimonials;
