import { Typography, Box, Grid, Hidden, Button } from "@mui/material";
import { Link } from "react-router-dom";
import image1 from "..//..//..//..//assets/RPS1.png";
import image2 from '..//..//..//..//assets/RPS2.jpeg'
 
const Pricingplans = () => {
  return (
    <div style={{ backgroundColor: "#F6F9FA", padding: "10px" }}>
      <Box style={{ marginTop: "0%" }}>
        <Typography
          style={{
            fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 2rem)",
            fontWeight: "500",
            padding: "10px",
          }}
        >
          Pricing Plans
        </Typography>

        <Typography
          style={{
            fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.3rem)",
            padding: "0px 20px 0px 20px",
            fontWeight: "500",
            color: "#5C6574",
          }}
        >
          Choose The Best Plan that Suits You
        </Typography>
      </Box>
      <div style={{ marginTop: "3%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <img
              src={image1}
              alt="Restaurant"
              style={{
                width: "100%",
                height: "80%",
                padding: "10px 10px 0px 10px",
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ mt: 2 }}>
            <Box style={{ textAlign: "left" }}>
              <Typography
                style={{
                  fontSize: "clamp(0.8rem, 0.4737rem + 2.1053vw, 1.6rem)",
                  fontWeight: "500",
                  padding: "10px",
                  textAlign: "left",
                }}
              >
                Restaurant POS System
              </Typography>
              <Typography
                style={{
                  fontSize: "clamp(0.5rem, 0.2737rem + 2.1053vw, 1.1rem)",
                  fontWeight: "500",
                  padding: "10px",
                  color: "#5C6574",
                  textAlign: "left",
                }}
              >
                Navigate Your Budget with Ease: Explore our range of flexible
                pricing options tailored to suit the diverse needs of your
                restaurant. Get started today and revolutionize your restaurant
                management experience.
              </Typography>
              <Link to="/Restaurantplans">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#03989e",marginLeft:"2%" }}
                >
                  Get Pricing
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Hidden smUp>
         <Grid item xs={12} md={6} >
            <img
              src={image2}
              alt="Restaurant"
              style={{
                width: "100%",
                height: "80%",
                padding: "50px 10px 0px 10px",
              }}
            />
          </Grid>
         </Hidden>
          <Grid item xs={12} md={6} >
            <Box style={{ textAlign: "left" }}>
              <Typography
                style={{
                  fontSize: "clamp(0.8rem, 0.4737rem + 2.1053vw, 1.6rem)",
                  fontWeight: "500",
                  padding: "10px 10px 10px 10px",
                  paddingTop:"30px",
                  textAlign: "left",
                }}
              >
                General POS System
              </Typography>
              <Typography
                style={{
                  fontSize: "clamp(0.5rem, 0.2737rem + 2.1053vw, 1.1rem)",
                  fontWeight: "500",
                  padding: "10px",
                  color: "#5C6574",
                  textAlign: "left",
                }}
              >
                Find Your Perfect Fit: Check out Our Easy Pricing Plans for
                Retail. We've got options for all sizes of stores, from small
                boutiques to big chains. Just clear prices to help your business
                thrive with our user-friendly POS system.
              </Typography>
              <Link to="/Retailplans">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#03989e",marginLeft:"2%" }}
                >
                  Get Pricing
                </Button>
              </Link>
            </Box>
          </Grid>
         <Hidden smDown>
         <Grid item xs={12} md={6}>
            <img
              src={image2}
              alt="Restaurant"
              style={{
                width: "100%",
                height: "80%",
                padding: "10px 10px 0px 10px",
              }}
            />
          </Grid>
         </Hidden>
        </Grid>
      </div>
    </div>
  );
};

export default Pricingplans;
