import React from "react";
import { Typography, Stack, Card } from "@mui/material";
import image3 from "..//..//..//..//assets/Rectangle 51.png";
import image4 from "..//..//..//..//assets/Rectangle 52.png";
import image5 from "..//..//..//..//assets/Rectangle 53.png";
import image6 from "..//..//..//..//assets/Group 49.png";

const cardData = [
  { imageUrl: image3, title1: "2000+", title: "POS Software Users" },
  { imageUrl: image4, title1: "1000+", title: "Salesman Tracker" },
  { imageUrl: image5, title1: "250+", title: "CRM Software Users" },
  { imageUrl: image6, title1: "100+", title: "Travel Agency Software Users" },
];

const circularCardHoverStyle = {
  transition: "transform 0.3s",
  "&:hover": {
    transform: "scale(1.2)",
  },
};

const CircularCardContainer = ({ imageUrl }) => {
  const circularCardStyle = {
    position: "relative",
    width: 100,
    height: 100,
    backgroundColor: "#F6F9FA",
    margin: "20px",
    boxShadow: "none",
    ...circularCardHoverStyle,
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <Card
      style={circularCardStyle}
      sx={{ "&:hover": { transform: "scale(1.2)" } }}
    >
      <img src={imageUrl} alt="icon" style={imageStyle} />
    </Card>
  );
};

const solutionBoxStyle = {
  backgroundColor: "",
  padding: "1%",
  textAlign: "center",
};

function RestaurantOS() {
  return (
    <>
  
    <div div style={{backgroundColor:"#F6F9FA", padding:"20px"}}>
      <Typography
        sx={{
          fontSize: "clamp(0.8rem, 0.4737rem + 2.1053vw, 1.6rem)",
          fontWeight: "bold",
          textAlign: "center",
          padding: "20px 30px 0px 30px",
        }}
      >
        Restaurant Operating System
      </Typography>

      <Typography
        sx={{
          fontSize: "clamp(0.5rem, 0.2737rem + 2.1053vw, 1.1rem)",
          padding: "20px 20px 0px 20px",
        }}
      >
        UBS Bill offers cloud-based solutions for restaurants, bars, cafes, and
        more. From billing to CRM, inventory to menu management, we support
        various functions. With 1500+ clients across 15+ cities globally, we
        cater to standalone outlets and large chains.
      </Typography>
      <div style={solutionBoxStyle }>
        <div style={{ padding: "20px 0px 20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {cardData.map((data, index) => (
                <Stack key={index} alignItems={"center"}>
                  <CircularCardContainer imageUrl={data.imageUrl} />
                  <Typography style={{ fontSize: "clamp(0.5rem, 0.2737rem + 2.1053vw, 1.1rem)",  fontWeight: "bold" }}>
                    {data.title1}
                  </Typography>
                  <Typography style={{  fontSize: "clamp(0.5rem, 0.2737rem + 2.1053vw, 1.1rem)", fontWeight: "bold" }}>
                    {data.title}
                  </Typography>
                </Stack>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default RestaurantOS;
