import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";

const Pagenotfound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "70vh",
      }}
    >
      <Container>
        <Typography
          align="center"
          style={{ fontSize: "clamp(0.5rem, 0.2737rem + 2.1053vw, 1.1rem)", fontWeight: "bold" }}
        >
          404 - Not Found
        </Typography>
        <Typography  align="center" style={{ fontSize: "clamp(0.5rem, 0.2737rem + 2.1053vw, 1.1rem)",}}>
          Oops! This Page is Under Development
        </Typography>
        <div style={{ textAlign: "center", marginTop: "2rem", }}>
          <Button
            component={RouterLink}
            to="/"
            variant="contained"
            size="large"
            style={{ backgroundColor: "#03989e", color: "white" , fontSize: "clamp(0.2rem, 0.1737rem + 2.1053vw, 0.8rem)",}}
          >
            Back to Home
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Pagenotfound;
