import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Hidden,
  CircularProgress,
} from "@mui/material";
import Customsnackbar from "./Customsnackbar";
import { InputLabel } from "@mui/material";
import Divider from "@mui/material/Divider";
import axios from "axios";

function Checkout() {
  const [selectedPrice, setSelectedPrice] = useState(null);

  useEffect(() => {
    const storedPrice = localStorage.getItem("selectedPrice");
    setSelectedPrice(storedPrice);
  }, []);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phoneno: "",
    email: "",
    address: "",
    state: "",
    pincode: "",
    storename: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    phoneno: "",
    email: "",
    address: "",
    state: "",
    pincode: "",
    storename: "",
    message: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    let errorMessage = "";

    switch (field) {
      case "phoneno":
        value = value.replace(/\D/g, "").slice(0, 10);
        errorMessage = !/^[6-9]\d{9}$/.test(value)
          ? "Please enter a valid 10-digit number"
          : "";
        break;

      case "email":
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        errorMessage = !emailRegex.test(value)
          ? "Please enter a valid email address"
          : "";
        break;

      case "pincode":
        value = value.replace(/\D/g, "");
        break;

      default:
        value = value.replace(/[^a-zA-Z ]/g, "");
        break;
    }

    setErrors({ ...errors, [field]: errorMessage });
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    const requiredFields = [
      "firstname",
      "lastname",
      "phoneno",
      "email",
      "address",
      "state",
      "pincode",
      "storename",
      "message",
    ];
    const emptyFields = [];
    for (const field of requiredFields) {
      const fieldValue =
        typeof formData[field] === "string"
          ? formData[field].trim()
          : formData[field];
      if (!fieldValue) {
        emptyFields.push(field);
      }
    }
    if (emptyFields.length > 0) {
      setSnackbarMessage(
        `Please fill in the following fields: ${emptyFields.join(", ")}`
      );
      setOpenSnackbar(true);
      setSubmitting(false);
      return;
    }
    if (
      errors.firstname ||
      errors.lastname ||
      errors.phoneno ||
      errors.email ||
      errors.address ||
      errors.state ||
      errors.pincode ||
      errors.storename ||
      errors.message
    ) {
      setSnackbarMessage("Please fix validation errors");
      setOpenSnackbar(true);
      setSubmitting(false);
      return;
    }
    console.log(formData);
    setSuccessSnackbarOpen(true);

    setFormData({
      firstname: "",
      lastname: "",
      phoneno: "",
      email: "",
      address: "",
      state: "",
      pincode: "",
      storename: "",
      message: "",
    });
    axios
      .post("https://api.ubsbill.com/api/checkout-form", formData)
      .then(() => {
        setOpenSnackbar(false);
        setSuccessSnackbarOpen(true);
        try {
          window.location.href =
            "https://api.ubsbill.com/api/pay?amount=" +
            encodeURIComponent(selectedPrice);
          console.log("Payment successful");
        } catch (error) {
          console.error("Payment failed:", error);
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setSuccessSnackbarOpen(false);
  };

  return (
    <div>
      <Typography
        sx={{
          fontSize: "clamp(0.5rem, 0.5737rem + 2.1053vw, 1.8rem)",
          fontWeight: "bold",
          textAlign: "center",
          padding: "80px 20px 0px 20px",
          marginTop: "1%",
        }}
      >
        Checkout Form
      </Typography>

      {/* checkout form */}

      <Grid container justifyContent={"center"} padding={"30px 30px 0px 30px"}>
        <Hidden smUp>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            textAlign={"start"}
            padding={"0px 0px 0px 10px"}
          >
            <Box
              sx={{
                backgroundColor: "#F6F9FA",
                border: "1px solid #ccc",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                position: "relative",
                zIndex: 2,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding="10px 10px 10px 10px"
              >
                <Typography
                  component="span"
                  style={{
                    fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 1.5rem)",
                  }}
                >
                  Your Cart
                </Typography>
              </Box>

              <Divider />
              <Box
                display="flex"
                alignItems="center"
                padding="10px 10px 10px 10px"
              >
                <Typography
                  component="span"
                  style={{
                    fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.3rem)",
                  }}
                >
                  Product Name - POS Software
                </Typography>
              </Box>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                padding="10px 10px 10px 10px"
              >
                <Typography
                  component="span"
                  style={{
                    fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.3rem)",
                  }}
                >
                  Product Price -
                </Typography>
                {selectedPrice !== null && (
                  <Typography
                    style={{
                      fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.3rem)",
                    }}
                  >
                    {selectedPrice}
                  </Typography>
                )}
              </Box>
              <Divider />

              <Box display="flex" alignItems="center" padding="10px">
                <TextField
                  variant="outlined"
                  placeholder="Promo code"
                  sx={{
                    flexGrow: 1,
                    marginRight: { xs: "4px", md: "8px" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          sx={{
                            fontSize: { xs: "0.7rem", md: "0.8rem" },
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            backgroundColor: "#03989e",
                            borderRadius: "0 4px 4px 0",
                            paddingLeft: { xs: "8px", md: "12px" },
                            paddingRight: { xs: "8px", md: "12px" },
                          }}
                        >
                          Redeem
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Divider />

              <Box
                display="flex"
                alignItems="center"
                padding="10px 10px 10px 10px"
              >
                <Typography
                  component="span"
                  style={{
                    fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.3rem)",
                  }}
                >
                  Promo code -
                </Typography>
              </Box>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                padding="10px 10px 10px 10px"
              >
                <Typography
                  component="span"
                  style={{
                    fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.3rem)",
                  }}
                >
                  Total - {selectedPrice}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Grid paddingTop="20px">
            <Box
              sx={{
                width: "100%",
                padding: "25px",
                backgroundColor: "#F6F9FA",
              }}
            >
              <Typography
                fontWeight={"bold"}
                style={{
                  textAlign: "start",
                  fontSize: "clamp(0.5rem, 0.5737rem + 2.1053vw, 1.8rem)",
                }}
              >
                Billing Address
              </Typography>

              <Box mt={3} fontSize={"1.2rem"} padding={"5px 5px 5px 25px"}>
                <Grid
                  container
                  rowGap={2}
                  columnSpacing={2}
                  justifyContent={"center"}
                  textAlign={"start"}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <InputLabel htmlFor="firstname">First Name</InputLabel>
                      <TextField
                        name="firstname"
                        fullWidth
                        value={formData.firstname}
                        onChange={handleChange("firstname")}
                        size="small"
                        placeholder="First Name"
                        inputProps={{
                          minLength: 3,
                          maxLength: 50,
                          style: {
                            backgroundColor: "white",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InputLabel htmlFor="lastname">Last Name</InputLabel>
                      <TextField
                        fullWidth
                        placeholder="Last Name"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleChange("lastname")}
                        size="small"
                        InputProps={{
                          style: {
                            backgroundColor: "white",
                          },
                        }}
                        inputProps={{
                          minLength: 10,
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} sx={{ marginTop: "2px" }}>
                    <Grid item xs={12} md={6}>
                      <InputLabel htmlFor="phoneno">Phone No</InputLabel>
                      <TextField
                        fullWidth
                        name="phoneno"
                        value={formData.phoneno}
                        onChange={handleChange("phoneno")}
                        size="small"
                        placeholder="Phone No"
                        InputProps={{
                          style: {
                            backgroundColor: "white",
                            borderRadius: "5px",
                          },
                        }}
                        inputProps={{
                          minLength: 10,
                          maxLength: 10,
                        }}
                        error={!!errors.phoneno}
                        helperText={errors.phoneno}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <TextField
                        fullWidth
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange("email")}
                        size="small"
                        InputProps={{
                          style: {
                            backgroundColor: "white",
                          },
                        }}
                        inputProps={{
                          minLength: 10,
                          maxLength: 50,
                        }}
                        error={!!errors.email}
                        helperText={errors.email}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} sx={{ marginTop: "2px" }}>
                    <Grid item xs={12} md={6}>
                      <InputLabel htmlFor="storename">Store Name</InputLabel>
                      <TextField
                        name="storename"
                        fullWidth
                        value={formData.storename}
                        onChange={handleChange("storename")}
                        size="small"
                        placeholder="Store Name"
                        inputProps={{
                          minLength: 3,
                          maxLength: 50,
                          style: {
                            backgroundColor: "white",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel htmlFor="address">Address</InputLabel>
                      <TextField
                        name="address"
                        fullWidth
                        value={formData.address}
                        onChange={handleChange("address")}
                        size="small"
                        placeholder="Address"
                        inputProps={{
                          minLength: 3,
                          maxLength: 50,
                          style: {
                            backgroundColor: "white",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel htmlFor="state">State</InputLabel>
                      <TextField
                        fullWidth
                        name="state"
                        value={formData.state}
                        onChange={handleChange("state")}
                        size="small"
                        placeholder="state"
                        InputProps={{
                          style: {
                            backgroundColor: "white",
                            borderRadius: "5px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel htmlFor="pincode">Pin Code</InputLabel>
                      <TextField
                        fullWidth
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange("pincode")}
                        size="small"
                        placeholder="pincode"
                        InputProps={{
                          style: {
                            backgroundColor: "white",
                            borderRadius: "5px",
                          },
                        }}
                        inputProps={{
                          minLength: 10,
                          maxLength: 10,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} sx={{ marginTop: "2px" }}>
                    <Grid item xs={12} md={12}>
                      <InputLabel htmlFor="message">
                        How did You Here?
                      </InputLabel>
                      <TextField
                        name="message"
                        fullWidth
                        value={formData.message}
                        onChange={handleChange("message")}
                        size="small"
                        placeholder="message"
                        inputProps={{
                          minLength: 3,
                          maxLength: 50,
                          style: {
                            backgroundColor: "white",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-start">
                    <Button
                      sx={{
                        backgroundColor: "#00989E",
                        width: "10vw",
                        fontSize: "0.8rem",
                        color: "white",
                        padding: "10px 60px",
                        marginTop: "12px",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                        "&:hover": {
                          backgroundColor: "#d8eded",
                        },
                      }}
                      onClick={(e) => {
                        handleSubmit(e);
                       
                      }}
                    >
                      Pay_Now
                    </Button>
                  </Grid>

                  <Customsnackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    handleClose={handleCloseSnackbar}
                    message={snackbarMessage}
                    severity="error"
                  />
                  <Customsnackbar
                    open={successSnackbarOpen}
                    autoHideDuration={3000}
                    handleClose={handleCloseSnackbar}
                    message="Form Submitted Successfully!"
                    severity="success"
                  />
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* {card} */}
        <Hidden smDown>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={4.5}
            padding={"10px 0px 0px 100px"}
          >
            <Box
              sx={{
                backgroundColor: "#F6F9FA",
                border: "1px solid #ccc",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",

                zIndex: 2,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding="10px 10px 10px 10px"
              >
                <Typography
                  component="span"
                  style={{
                    fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 1.5rem)",
                  }}
                >
                  Your Cart
                </Typography>
              </Box>

              <Divider />
              <Box
                display="flex"
                alignItems="center"
                padding="10px 10px 10px 10px"
              >
                <Typography
                  component="span"
                  style={{
                    fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.3rem)",
                  }}
                >
                  Product Name - POS Software
                </Typography>
              </Box>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                padding="10px 10px 10px 10px"
              >
                <Typography
                  component="span"
                  style={{
                    fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.3rem)",
                  }}
                >
                  Product Price -
                </Typography>
                {selectedPrice !== null && (
                  <Typography
                    style={{
                      fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.3rem)",
                    }}
                  >
                    {selectedPrice}
                  </Typography>
                )}
              </Box>
              <Divider />

              <Box display="flex" alignItems="center" padding="10px">
                <TextField
                  variant="outlined"
                  placeholder="Promo code"
                  sx={{
                    flexGrow: 1,
                    marginRight: { xs: "4px", md: "8px" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          sx={{
                            fontSize: { xs: "0.7rem", md: "0.8rem" },
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            backgroundColor: "#03989e",
                            borderRadius: "0 4px 4px 0",
                            paddingLeft: { xs: "8px", md: "12px" },
                            paddingRight: { xs: "8px", md: "12px" },
                          }}
                        >
                          Redeem
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Divider />

              <Box
                display="flex"
                alignItems="center"
                padding="10px 10px 10px 10px"
              >
                <Typography
                  component="span"
                  style={{
                    fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.3rem)",
                  }}
                >
                  Promo code -
                </Typography>
              </Box>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                padding="10px"
                style={{ backgroundColor: "#03989e" }}
              >
                <Typography
                  component="span"
                  style={{
                    fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.3rem)",
                    color: "white", 
                  }}
                >
                  Total - {selectedPrice}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      {/* Loader */}
      {submitting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <CircularProgress color="primary" />
          Wait for Payment
        </div>
      )}

      {/* Snackbar */}
      <Customsnackbar
        open={openSnackbar}
        autoHideDuration={3000}
        handleClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity="error"
      />
      <Customsnackbar
        open={successSnackbarOpen}
        autoHideDuration={3000}
        handleClose={handleCloseSnackbar}
        message="Form Submitted Successfully!"
        severity="success"
      />
    </div>
  );
}

export default Checkout;
