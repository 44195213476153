import React from "react";
import { Typography, Box, Grid, Hidden } from "@mui/material";
import restaurantImage from "..//..//..//assets/RestaurantPOS.png";
import SS1 from "..//..//..//assets/SS1.png";
import SS2 from "..//..//..//assets/SS2.png";
import SS3 from "..//..//..//assets/SS3.png";
import SS4 from "..//..//..//assets/SS4.png";
import Cards from './Subcompo/cards'
import RestaurantOS from './Subcompo/RestaurantOS'
import Bookademo from "./Subcompo/Bookademo";
import { Helmet } from "react-helmet";
const imageArray = [
  {
    title:
      "One efficient POS software solution to take your cafe or restaurant business to new heights",
    description:
      "At SYNTIARO, we envision a world where businesses can seamlessly access skilled and talented professionals from across the globe, breaking barriers of physical location and time zones. We believe that remote staffing is the future of work, enabling companies to tap into a vast pool of talent without being limited by geographical boundaries.",
    image: SS1,
  },
  {
    title:
      "Streamlined Service: Restaurant POS Dashboard for Seamless Management",
    description:
      "Monitor real-time income, identify top-selling dishes for menu adjustments and stock management, generate detailed food bill reports for accurate accounting and financial analysis, and manage user profiles securely within the POS system for tailored customization and enhanced data security.",
    image: SS2,
  },
  {
    title: "Efficient Transactions: Restaurant POS Cash Register",
    description:
      "Seamless transactions: Versatile cash register with cash, UPI, and card options, separate balances for accurate tracking, designated cashier for accountability and security.",
    image: SS3,
  },
  {
    title: "Stock Status: Detailed Inventory Listings",
    description:
      "Detailed inventory listings: Essential product details including name, issuance date, quantity, category, and price for precise management. Stay updated on expiration dates to minimize waste and uphold quality. Streamlined tracking for restocking and stock level assessment, enabling informed decisions on purchasing, pricing, and efficiency optimization.",
    image: SS4,
  },
];

const RestaurantPOS = () => {
  return (
    <>
     <Helmet>
        <title>RestaurantPOS | UBS Bill</title>
      </Helmet>
    <div style={{ marginTop: "2%" }}>
     <Hidden smDown>
     <img src={restaurantImage} alt="Restaurant" style={{ width: "100%" }} />
     </Hidden>
      <Hidden smUp>
      <img src={restaurantImage} alt="Restaurant" width={"100%"} style={{paddingTop:"3.9rem"}}/>
      </Hidden>
      <Box style={{ marginTop: "2%" }}>
        <Typography
          style={{
            fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 2rem)",
            fontWeight: "500",
            padding: "10px",
          }}
        >
          A restaurant POS software that offers everything
        </Typography>

        <Typography
          style={{
            fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.2rem)",
            padding: "0px 20px 0px 20px",
            fontWeight: "500",
            color: "#5C6574",
          }}
        >
          Restaurant billing software that handles everything with a few clicks
        </Typography>
      </Box>


      {imageArray.map((item, index) => (
        <Grid key={index} container spacing={0} style={{}}>
          {index % 2 === 0 ? (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <img
                  src={item.image}
                  alt="Innovation"
                  style={{
                    width: "80%",
                    height: "80%",
                    marginTop: "20px",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ p: "30px", textAlign: "left" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.2rem)",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1rem)",
                      color: "#5C6574",
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            </React.Fragment>



          ) : (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <Box sx={{ p: "30px ", textAlign: "left" }}>
                  <Hidden smUp>
                    <Grid item xs={12} sm={6}>
                      <img
                        src={item.image}
                        alt="Innovation"
                        style={{
                          width: "100%",
                          height: "80%",
                          marginTop: "20px",
                          
                        }}
                      />
                    </Grid>
                  </Hidden>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.2rem)",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1rem)",
                      color: "#5C6574",
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>

              <Hidden smDown>
                <Grid item xs={12} sm={6}>
                  <img
                    src={item.image}
                    alt="Innovation"
                    style={{
                      width: "90%",
                      height: "80%",
                      marginTop: "20px",
                    }}
                  />
                </Grid>
              </Hidden>
            </React.Fragment>






          )}
        </Grid>
      ))}

      <Cards/>
      <RestaurantOS/>
      <Bookademo/>
    </div>
    </>
  );
};

export default RestaurantPOS;
