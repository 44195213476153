import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  Typography,
  TextField,
  IconButton,
  Backdrop,
  CircularProgress,
  Divider,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Customsnackbar from "./Customsnackbar";
import axios from "axios";
import { MicNone } from "@mui/icons-material";

const Popupform = ({ isOpen, onClose }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose1 = () => {
    setOpen(false);
   
  };
  const handleOpen = () => {
    const requiredFields = ["name", "email", "phoneno", "city", "state"];
    for (const field of requiredFields) {
      if (!formData[field]) {
        return;
      }
    }
    setOpen(true);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneno: "",
    city: "",
    state: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phoneno: "",
    city: "",
    state: "",
  });

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    let errorMessage = "";

    switch (field) {
      case "phoneno":
        value = value.replace(/\D/g, "").slice(0, 10);
        errorMessage = !/^[6-9]\d{9}$/.test(value)
          ? "Please enter a valid 10-digit number"
          : "";
        break;

      case "email":
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        errorMessage = !emailRegex.test(value)
          ? "Please enter a valid email address"
          : "";
        break;
      default:
        value = value.replace(/[^a-zA-Z ]/g, "");
        break;
    }
    setErrors({ ...errors, [field]: errorMessage });
    setFormData({ ...formData, [field]: value });
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalStyle, setModalStyle] = useState({
    top: "-50%",
  });

  useEffect(() => {
    if (isOpen) {
      setModalStyle({
        top: "50%",
        transition: "top 0.3s ease",
      });
    } else {
      setModalStyle({
        top: "-50%",
        transition: "top 0.3s ease",
      });
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://api.ubsbill.com/api/request-for-enquiry-Form/register",
        formData
      )
      .then((response) => {
        console.log("form submitted", response.data);
        setSuccessSnackbarOpen(true);
        setFormData({
          name: "",
          email: "",
          phoneno: "",
          city: "",
          state: "",
        });
        setSubmitting(false);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
        setOpenSnackbar(true); 
        setLoading(false);
      })
     
       
     
    const requiredFields = ["name", "email", "phoneno", "city", "state"];
    const emptyFields = [];
    for (const field of requiredFields) {
      const fieldValue =
        typeof formData[field] === "string"
          ? formData[field].trim()
          : formData[field];
      if (!fieldValue) {
        emptyFields.push(field);
      }
    }
    if (emptyFields.length > 0) {
      setSnackbarMessage(
        `Please fill in the following fields: ${emptyFields.join(", ")}`
      );
      setOpenSnackbar(true);
      return;
    }
    if (
      errors.name ||
      errors.email ||
      errors.phoneno ||
      errors.city ||
      errors.email ||
      errors.state
    ) {
      setSnackbarMessage("Please fix validation errors");
      setOpenSnackbar(true);
      return;
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setSuccessSnackbarOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      style={{
        border: "none",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          outline: "none",
          maxWidth: 600,
          borderRadius :"10px",
          bgcolor: "white",
          backgroundColor: "#F6F9FA",
          p: 4,
          maxHeight: "90vh",
          overflowY: "auto",
          border:"none",
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": {
            width: 0,
          },
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0, color: "black" }}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
        <Typography
          id="inquiry-form-modal"
          variant="h6"
          component="h2"
          textAlign="center"
          gutterBottom
          color="#03989e"
        >
          Book a Demo
        </Typography>
        <Divider />
        <Typography
          style={{
            fontSize: "clamp(0.8rem, 0.4737rem + 0.0053vw, 0.5rem)",
            paddingTop: "20px",
          }}
        >
          Reach out to us today if you own a restaurant and want to explore
          reselling opportunities or partnerships, or if you require any
          assistance
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            textAlign="start"
            style={{ paddingTop: "20px" }}
          >
            <Grid container spacing={2} sx={{ marginTop: "0%" }}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Name"
                  name="name"
                  fullWidth
                  value={formData.name}
                  onChange={handleChange("name")}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    minLength: 3,
                    maxLength: 100,
                    style: {
                      backgroundColor: "white",
                      borderRadius: "9px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Email ID"
                  name="email"
                  fullWidth
                  value={formData.email}
                  onChange={handleChange("email")}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    minLength: 3,
                    maxLength: 50,
                    style: {
                      backgroundColor: "white",
                      borderRadius: "9px",
                    },
                  }}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: "0%" }}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Phone No"
                  fullWidth
                  size="small"
                  value={formData.phoneno}
                  onChange={handleChange("phoneno")}
                  variant="outlined"
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                      borderRadius: "9px",
                    },
                  }}
                  inputProps={{
                    maxLength: 10,
                    minLength: 10,
                  }}
                  error={!!errors.phoneno}
                  helperText={errors.phoneno}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="City"
                  fullWidth
                  size="small"
                  value={formData.city}
                  onChange={handleChange("city")}
                  variant="outlined"
                  inputProps={{
                    minLength: 3,
                    maxLength: 50,
                    style: {
                      backgroundColor: "white",
                      borderRadius: "9px",
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: "0%" }}>
              <Grid item xs={12} md={12}>
                <TextField
                  label="State"
                  fullWidth
                  size="small"
                  value={formData.state}
                  onChange={handleChange("state")}
                  inputProps={{
                    minLength: 3,
                    maxLength: 50,
                    style: {
                      backgroundColor: "white",
                      borderRadius: "9px",
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginTop: "10px",
                  backgroundColor: "#03989e",
                }}
                onClick={(e) => {
                  handleSubmit(e);
                  handleOpen();
                }}
              >
                Submit
              </Button>
<Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open && !successSnackbarOpen && loading}
                onClick={handleClose1}
              />
            </Grid>
          </Grid>
        </form>
        <Customsnackbar
          open={openSnackbar}
          autoHideDuration={1200}
          handleClose={handleCloseSnackbar}
          message={snackbarMessage}
          severity="error"
        />

        <Customsnackbar
          open={successSnackbarOpen}
          autoHideDuration={1200}
          handleClose={handleCloseSnackbar}
          message="Form Submitted Successfully!"
          severity="success"
        />
      </Box>
    </Modal>
  );
};

export default Popupform;
