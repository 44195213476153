import { Box, Grid, Typography, Card } from "@mui/material";
import React from "react";
import phoneIcon from "../../../assets/Phone.png";
import mailIcon from "../../../assets/Email.png";
import locIcon from "../../../assets/Location.png";
import ContactUs from "../../Forms/ContactUs";
import Contact2 from "../../../assets/Contact2.png";
import { Helmet } from "react-helmet";
const iconStyle = {
  width: "17px",
  margin: "10px",
};

function Contact() {
  return (
    <div>
      <Helmet>
        <title>Contact | UBS Bill</title>
      </Helmet>
      <Grid container padding={"90px 20px 0px 20px"}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <img
            src={Contact2}
            alt=""
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          textAlign={"start"}
          padding={"40px 5px 0px 5px"}
        >
          <Typography
            fontWeight={"bold"}
            fontSize={"clamp(0.9rem, 0.8737rem + 2.1053vw, 2rem)"}
            paddingLeft={"10px"}
          >
            Get In Touch
          </Typography>
          <br />
          <Box display={"flex"} alignItems={"center"}>
            <img
              alt=""
              width={"35x"}
              style={{ margin: "0px -10px 10px 10px", color: "#5C6574" }}
            />
            <Typography
              component={"span"}
              style={{
                fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.2rem)",
              }}
            >
              We value your connection! Whether you have questions, feedback, or
              just want to say hello, we're here for you. Reach out to us
              through our website, email, or social media channels, and we'll be
              delighted to assist you.
            </Typography>{" "}
          </Box>
        </Grid>
      </Grid>

      {/* contact form */}
      <Grid container padding={"30px 30px 0px 30px"}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ContactUs />
        </Grid>

        {/* {card} */}
        <Grid item xs={12} sm={12} md={6} lg={6} textAlign={"start"}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ paddingTop: "5%" }}
          >
            <Box
              sx={{
                width: 450,
                height: 400,
                backgroundColor: "white",
                border: "1px solid #ccc",
                padding: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                position: "relative",
                zIndex: 2,
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                textAlign="start"
                padding="0px 10px 0px 10px"
              >
                <Box display="flex" alignItems="center">
                  <img src={mailIcon} alt="" style={iconStyle} />
                  <Typography
                    component="span"
                    style={{
                      fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.2rem)",
                    }}
                  >
                    info@ubsbill.com
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" fontSize="2.5vmin">
                  <img src={phoneIcon} alt="" style={iconStyle} />
                  <Typography
                    component="span"
                    style={{
                      fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.2rem)",
                    }}
                  >
                    {" "}
                    +91 911 211 3322
                  </Typography>
                </Box>

                <Typography
                  fontWeight="bold"
                  style={{
                    fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.1rem)",
                  }}
                  paddingLeft="20px"
                  paddingTop="15px"
                >
                  Pune Head Office
                </Typography>
                <br />
                <Box display="flex" alignItems="center">
                  <img src={locIcon} alt="" width="17px"  style={{ margin: "0px 10px 2px 10px" }} />
                  <Typography
                    component="span"
                    style={{
                      fontSize: "clamp(0.2rem, 0.1737rem + 2.1053vw, 1rem)",
                    }}
                  >
                    Shree Ganesh Building, First floor No-101, Gurudwara Chowk,
                    Near Akurdi Railway Station Rd, Pune Maharashtra - 411033.
                  </Typography>{" "}
                </Box>

                <Typography
                  fontWeight="bold"
                  style={{
                    fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.1rem)",
                  }}
                  paddingLeft="20px"
                  paddingTop="15px"
                >
                  UAE Sales Office



                  
                </Typography>
                <Box display="flex" alignItems="center" fontSize="2.5vmin">
                  <img src={phoneIcon} alt="" style={iconStyle} />
                  <Typography
                    component="span"
                    style={{
                      fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.2rem)",
                    }}
                  >
                    {" "}
                    +971 589 155 481
                  </Typography>
                </Box>
                <br />
                <Box display="flex" alignItems="center">
                  <img
                    src={locIcon}
                    alt=""
                    width="17px"
                    style={{ margin: "0px 10px 2px 10px" }}
                  />
                  <Typography
                    component="span"
                    style={{
                      fontSize: "clamp(0.2rem, 0.1737rem + 2.1053vw, 1rem)",
                    }}
                  >
                  Sharjah & Dubai in UAE
                  </Typography>{" "}
                </Box>
              </Grid>
            </Box>
            <Box
              sx={{
                width: 400,
                height: 500,
                backgroundColor: "#03989E",
                border: "1px solid #ccc",
                padding: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                position: "relative",
                marginLeft: "-200px",
                zIndex: 1,
              }}
            ></Box>
          </Box>
        </Grid>
      </Grid>

      {/* map */}
      <Grid container paddingTop={"5%"}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div
            style={{ position: "relative", paddingTop: "56.25%", height: 0 }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.404737877893!2d73.76082348501063!3d18.64582522015825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9918b26f109%3A0xcca7698e1f1a72dc!2sUBS%20Bill!5e0!3m2!1sen!2sin!4v1715249721429!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              style={{
                position: "absolute",
                top: 0,
                left: "20%",
                width: "60%",
                height: "60%",
              }}
              
            ></iframe>
          </div>
        </Grid>
      </Grid>
    </div>
  );
  
}



export default Contact;


