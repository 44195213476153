import React from "react";
import { useState } from "react";
import { Link } from 'react-router-dom'; 
import {

  Grid,
 
  Typography,
  Box,
  Button,
  Hidden,

} from "@mui/material";
import POS from "../../../../assets/POS.png";

const Header = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100%",
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div>
        <Grid
          container
          spacing={2}
          mt={5}
          style={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          <Hidden smDown>
            <Grid item xs={12} md={6} paddingRight={"5%"}>
              <Typography
                style={{
                  fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 2.5rem)",
                  fontWeight: "bold",
                  paddingTop: "12%",
                  color: "#E3B700",
                  textAlign: "start",
                  padding: "60px 35px 0px 35px",
                }}
              >
                <span style={{ color: "black" }}>
                  We provide the <br />
                  best
                </span>
                <span style={{ color: "#03989E" }}> POS software</span>
              </Typography>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 1rem)",
                    textAlign: "start",
                    padding: "0px 35px 0px 35px",
                    color: "#5C6574",
                  }}
                >
                  Experience seamless transactions with our premier POS
                  software, Benefit from advanced features, unparalleled
                  reliability, and unmatched support, making us your trusted
                  partner in achieving success.
                </Typography>
                <Box
                  style={{
                    textAlign: "start",
                    marginTop: "20px",
                    padding: "0px 35px 0px 35px",
                  }}
                >
                  <Box
                    className="box"
                    style={{
                      width: "auto",
                    }}
                  >
                    <Link to="/Demo">
                      {" "}
                     
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#03989e",
                        }}
                      >
                        Book a Demo
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12} md={6} paddingBottom={"3%"} paddingRight={"5%"}>
              <Typography
                style={{
                  fontSize: "5vmin",
                  fontWeight: "bold",
                 
                  paddingTop: "12%",
                  color: "#E3B700",
                  textAlign: "start",
                  padding: "50px 20px 0px 20px",
                }}
              >
                <span style={{ color: "black" }}>
                  We provide the <br />
                  best
                </span>
                <span style={{ color: "#03989E" }}> POS software</span>
              </Typography>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "clamp(0.6rem, 0.3737rem + 2.1053vw, 1rem)",
                    textAlign: "start",
                    padding: "0px 20px 0px 20px",
                    color: "#5C6574",
                  }}
                >
                  Experience seamless transactions with our premier POS
                  software, Benefit from advanced features, unparalleled
                  reliability, and unmatched support, making us your trusted
                  partner in achieving success.
                </Typography>
                <Box
                  style={{
                    textAlign: "start",
                    marginTop: "20px",
                    padding: "0px 20px 0px 20px",
                  }}
                >
                  <Box
                    className="box"
                    style={{
                      width: "auto",
                    }}
                  >
                   <Link to="/Demo">
                      {" "}
                     
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#03989e",
                        }}
                      >
                        Book a Demo
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Hidden>

          <Grid item xs={12} md={6}>
            <img
              src={POS}
              alt=""
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Header;
