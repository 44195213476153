import { Typography, Grid, Hidden } from "@mui/material";
import Image from "..//..//..//assets/pricing.png";
import Pricingplans from "./Subcompo/Pricingplans";
import Cards from "./Subcompo/Cards";
import Testimonials from "./Subcompo/Testimonials";
import { Helmet } from "react-helmet";
const GeneralPOS = () => {
  return (
    <div>
      <Helmet>
        <title>Pricing | UBS Bill</title>
      </Helmet>
      <Grid container spacing={2} sx={{ mt: 30 }}>
        <Grid style={{marginTop:"-30%"}}>
        <Hidden smUp>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <img
              src={Image}
              alt="Retail POS"
              style={{ width: "50%", height: "auto" }}
            />
          </Grid>
        </Hidden>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div style={{ padding: "0 30px", textAlign: "left" }}>
            <Typography
              style={{
                marginBottom: 8,
                fontSize: "clamp(1.5rem, 0.4737rem + 2.1053vw, 2rem)",
              }}
            >
              We’ve got a plan that’s perfect for you !
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ marginBottom: 16 }}
            >
              Unlock your potential with our tailored plan designed just for
              you! Experience seamless performance and unparalleled efficiency
              with our cutting-edge software solutions.
            </Typography>
          </div>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: -20 }}>
            <img
              src={Image}
              alt="Retail POS"
              style={{ width: "50%", height: "auto" }}
            />
          </Grid>
        </Hidden>
      </Grid>

      <Pricingplans />
      <Cards />
      <Testimonials />
    </div>
  );
};

export default GeneralPOS;
