

import React from "react";
import { Typography, Box, Grid, Hidden } from "@mui/material";
import IMGS2 from "..//..//..//..//assets/IMGS2.png";
import IMGS3 from "..//..//..//..//assets/IMGS3.png";
import IMGS4 from "..//..//..//..//assets/IMGS4.png";
import IMGS5 from '..//..//..//..//assets/IMGS5.png'
import IMGS6 from "..//..//..//..//assets/IMGS6.png"



const imageArray = [
  {
    title:
      "       Safe & Secure Payment Processing",
    description:
      "Safe and secure payment processing ensures encrypted transactions, PCI compliance, and fraud prevention tools for protecting sensitive financial information and maintaining customer trust.",
    image: IMGS6,
  },
  {
    title:
      "Enhanced Customer Satisfaction & Loyalty",
    description:
      "By providing excellent customer service, personalized experiences, and meeting or exceeding customer expectations, you cultivate loyalty among your customer base, leading to repeat business and positive word-of-mouth.",
    image: IMGS2,
  },
  {
    title: "Data-Driven Decision Making & Improved Profitability",
    description:
      "Advanced reporting and analytics capabilities within the POS system enable businesses to analyze sales trends, track key performance indicators, and make informed decisions about pricing, promotions, and inventory management strategies.",
    image: IMGS3,
  },
  {
    title: "Seamless and Personalized Shopping Experiences",
    description:
      " Providing a cohesive and tailored experience for customers across various touchpoints, including online platforms, mobile apps, and physical stores, based on their preferences, purchase history, and behavior.",
    image: IMGS4,
  },
  {
    title: "Consistency Across Online and Offline Channels",
    description:
      " Ensuring a seamless and integrated experience for customers regardless of whether they are interacting with the brand online or in physical stores, maintaining brand identity and customer satisfaction across all channels.",
    image: IMGS5,
  },
];

const Subcompo = () => {
  return (
    <div style={{ marginTop: "2%" }}>

      <Box style={{ marginTop: "2%" }}>
        

        
      </Box>
      {imageArray.map((item, index) => (
        <Grid key={index} container spacing={0} style={{}}>
          {index % 2 === 0 ? (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <img
                  src={item.image}
                  alt="Innovation"
                  style={{
                    width: "80%",
                    height: "80%",
                    marginTop: "20px",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ p: "30px", textAlign: "left" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.2rem)",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1rem)",
                      color: "#5C6574",
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <Box sx={{ p: "30px ", textAlign: "left" }}>
                  <Hidden smUp>
                    <Grid item xs={12} sm={6}>
                      <img
                        src={item.image}
                        alt="Innovation"
                        style={{
                          width: "90%",
                          height: "80%",
                          marginTop: "20px",
                        }}
                      />
                    </Grid>
                  </Hidden>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.2rem)",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1rem)",
                      color: "#5C6574",
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>

              <Hidden smDown>
                <Grid item xs={12} sm={6}>
                  <img
                    src={item.image}
                    alt="Innovation"
                    style={{
                      width: "90%",
                      height: "80%",
                      marginTop: "20px",
                    }}
                  />
                </Grid>
              </Hidden>
            </React.Fragment>
          )}
        </Grid>
      ))}


    </div>
  );
};

export default Subcompo;

