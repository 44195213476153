import { Box, Grid, Typography, Hidden } from "@mui/material";
import Header1 from "../../assets/Header1.png";
import Image4 from "../../assets/Image4.png";
import Image5 from "../../assets/Image5.png";
import Image6 from "../../assets/Image6.png";
import Image7 from "../../assets/Image7.png";
import { Helmet } from "react-helmet";
const TermsAndCondition = () => {
  return (
    <div>
      <Helmet>
        <title>TermsAndCondition | UBS Bill</title>
      </Helmet>
      <Box
        position="relative"
        style={{ height: "100%", display: "flex", alignItems: "flex-end" }}
      >
       <Hidden smDown> <img src={Header1} alt="logo here" style={{ width: "100%" }} /></Hidden>
       <Hidden smUp> <img src={Header1} alt="logo here" style={{ width: "100%" ,paddingTop:"4.3rem"}} /></Hidden>
        <Box
          position="absolute"
          bottom="10px"
          left="10px"
          color="white"
          padding="10px"
          borderRadius="5px"
        >
          <Typography style={{ margin: 0, fontSize: "3.5vmin" }}>
            Terms & Condition
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
            marginTop: "3%",
          }}
        >
          Terms & Condition
        </Typography>
        <Typography
          sx={{
            p: "10px 20px 0px 20px",
            fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",

            textAlign: "left",
            color: "#5C6574",
          }}
        >
          Please read the following Terms and Conditions carefully before using
          any products or services on www.ubsbill.com. By purchasing, installing
          or using products or services from UBSBill Pvt Ltd, we assume that
          Customers read and agree to all the terms of this policy.
        </Typography>
      </Box>

      {/* 1 */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <img
            src={Image4}
            alt="terms"
            style={{
              width: "65%",
              height: "65%",
              marginTop: "55px",
              marginLeft: "15px",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          mt={5}
          style={{ padding: "0px 30px 0px 30px" }}
        >
          <Box sx={{ p: "10px", textAlign: "left" }}>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
              }}
            >
              Introduction
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
                color: "#5C6574",
              }}
            >
              To coverage full scope for managing restaurant, we created certain
              add-on which restaurateurs can avail via paying through online
              Payment Gateway. Below are the Terms and Conditions for the same.
              These Terms of Service (the “Terms”) are intended to make you
              aware of your legal rights and responsibilities with respect to
              your access to and use of the website at www.ubsbill.com (the
              “Site”) and any related web applications whether existing now or
              in the future (“the App”) of UBSBill Private Limited or “We” or
              “Us”.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* 2 */}
      <Grid container spacing={3}>
        <Hidden smUp>
          <Grid item xs={12} sm={6}>
            <img
              src={Image5}
              alt="terms"
              style={{
                width: "70%",
                height: "70%",
                marginTop: "20px",
                marginLeft: "15px",
              }}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6} style={{ padding: "0px 30px 0px 40px" }}>
          <Box sx={{ p: "10px", textAlign: "left" }}>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
              }}
            >
              Definitions
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
                color: "#5C6574",
              }}
            >
              “Services” will include business applications like Hotel
              Management Software and Franchise Management Software, which are
              integrated to UBSBill. “Content” means the content that UBSBill
              Pvt Ltd creates and make available in connection with the Services
              including, but not limited to, visual interfaces, interactive
              features, graphics, design, compilation, computer code, products,
              software, functionalities, aggregate ratings, reports and other
              usage-related data in connection with activities associated with
              your account and all other elements and components of the Services
              excluding Your Content and third party content. It will include
              (but is not limited to) images, photos, audio, video, and all
              other forms of information or data. “Your content” or “User
              Content” means content that you upload, share or transmit to,
              through or in connection with the Services, such as likes,
              ratings, reviews, images, photos, messages, customer information,
              information on number of tables, table availability, table usage
              and any other materials that you display or are displayed in your
              account.
            </Typography>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={6}>
            <img
              src={Image5}
              alt="terms"
              style={{
                width: "70%",
                height: "70%",
                marginTop: "20px",
                marginLeft: "15px",
              }}
            />
          </Grid>
        </Hidden>
      </Grid>
      {/* 3 */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <img
            src={Image6}
            alt="terms"
            style={{
              width: "70%",
              height: "70%",
              marginTop: "15px",
              marginLeft: "15px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: "0px 30px 0px 40px" }}>
          <Box sx={{ p: "10px", textAlign: "left" }}>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
              }}
            >
              Objects of the Agreement
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
                color: "#5C6574",
              }}
            >
              The object of this Agreement is to allow the Client access to the
              Service provided by the Provider in relation to the Hotels listed
              in the Specific Terms & Conditions. Access to the Service will be
              made by online connection. For that purpose, the Service Provider
              will provide the Client with a strictly confidential access code
              for its exclusive use. The Client shall adopt all measures
              necessary to keep this code confidential, and the Service Provider
              will not be liable for any consequences caused by the use of the
              access code by unauthorized third parties. The Service Provider
              reserves the right to amend its programs, as well as the system
              for supplying data and the technical characteristics for accessing
              the Service.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* 4 */}
      <Grid container spacing={3}>
        <Hidden smUp>
          <Grid item xs={12} sm={6}>
            <img
              src={Image7}
              alt="terms"
              style={{
                width: "70%",
                height: "70%",
                marginTop: "20px",
                marginLeft: "15px",
              }}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6} style={{ padding: "0px 40px 0px 40px" }}>
          <Box sx={{ p: "10px", textAlign: "left" }}>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
              }}
            >
              The Service
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
                color: "#5C6574",
              }}
            >
              The Service Provider will be entitled to modify the Service from
              time to time, including by adding or deleting features and
              functions, in an effort to improve the tool and to make any
              changes to the Service which are necessary to comply with any
              applicable law or which do not materially affect the nature or
              quality of the Service. However, the Service Provider will not
              introduce any change that materially reduce the functionality of
              the version of the Service purchased by the Client. The Client may
              purchase to additional versions or features of the Service by
              entering into a new agreement with the Service Provider. The
              Service Provider may use third parties to provide all or part of
              the Service. The Client acknowledges and accepts that Service
              Provider is the owner of the aggregated data obtained as a result
              of the provision of the Survey Services. Review Rank may , without
              any limitation, use the aggregated data for their own purposes and
              publish them in any format. In no event the aggregate data will
              include personal data owned by the Client and/or identification
              data of the Client.
              <br />
            </Typography>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={6}>
            <img
              src={Image7}
              alt="terms"
              style={{
                width: "70%",
                height: "70%",
                marginTop: "20px",
                marginLeft: "15px",
              }}
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default TermsAndCondition;
