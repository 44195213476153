import { Box, Typography,Card, CardContent, CardMedia, Grid, } from "@mui/material";
import React from "react";
import image1 from "../../../../assets/Rectangle 281.png";
import image2 from "../../../../assets/Rectangle 286.png";
import image3 from "../../../../assets/Rectangle 287.png";
import image4 from "../../../../assets/Rectangle 288.png";
import image5 from "../../../../assets/Rectangle 293.png";
import image6 from "../../../../assets/Rectangle 295.png";
import image7 from "../../../../assets/Rectangle 298.png";
import image8 from "../../../../assets/Rectangle 296.png";


function Outlet() {
  const cards = [
    {
      image: image1,
      title: "QSR",
      
    },
    {
      image: image2,
      title: "Dine-in",
    
    },
    {
      image: image3,
      title: "Cafe",
      
    },
    {
      image: image4,
      title: "Cloud Kitchen",
      
    },
    {
      image: image5,
      title: "Food Truck",
      
    },
    {
      image: image6,
      title: "Bakery",
    
    },
    {
      image: image7,
      title: "Ice-Cream",
      
    },
    {
      image: image8,
      title: "Pubs & Bar",
      
    },
  ];
  return (
    <>
     <Box padding={"10px 30px 20px 30px"}>
     <Typography sx={{ fontSize: "clamp(0.8rem, 0.4737rem + 2.1053vw, 1.6rem)", fontWeight: "bold",textAlign:"center",marginTop:"3%" }}>
        Our Outlet Types
      </Typography>
      <Typography sx={{ fontSize: "clamp(0.5rem, 0.2737rem + 2.1053vw, 1.1rem)",textAlign:"center",paddingTop:"10px",color: "#5C6574", }}>
        Restaurant Management POS is built for all types of restaurant sizes and
        formats from single outlets to 100+ outlet chains. Select your format to
        see how UBS Bill Solution can help you run better.
      </Typography>
     </Box>
     <Grid container spacing={5} padding={"0% 10% 2% 10%"}>
        {cards.map((card, index) => (
          <Grid
            item
            key={index}
            xs={6}
            sm={6}
            md={3}
            lg={3}
            alignItems={"space-between"}
          >
            <Card
              sx={{
                height: "90%", 
                padding: "5px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                background: index % 2 === 0 ? "#A9E0E2" : "#E4F6F6", 
              }}
            >
               <CardMedia
                component="img"
                image={card.image}
                sx={{
                  maxHeight: "15vmin",
                  maxWidth: "15vmin",
                  margin: " auto",
                  marginTop: "8%",
                  transition: "transform 0.3s", 
                  "&:hover": {
                    transform: "scale(1.2)", 
                  },
                }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    marginTop: "2%",
                    fontSize: "2.5vmin",
                  }}
                >
                  {card.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Outlet;
