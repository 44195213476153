import Typography from '@mui/material/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Grid } from '@mui/material';
import IMGS1 from '..//..//..//..//assets/IMGS1.png';
import IMGS2 from '..//..//..//..//assets/IMGS2.png';
import IMGS3 from '..//..//..//..//assets/IMGS3.png';
import IMGS4 from '..//..//..//..//assets/IMGS4.png';
import IMGS5 from '..//..//..//..//assets/IMGS5.png';

const Imageslider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  // Define an array of image URLs
  const images = [IMGS1, IMGS2, IMGS3, IMGS4, IMGS5];

  return (
    <div style={{ backgroundColor: '#F6F9FA', padding: '40px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography
              gutterBottom
              style={{ fontSize: 'clamp(1rem, 0.3737rem + 2.1053vw, 1.8rem)',fontWeight:"500" }}
            >
              Optimize Daily Operations With Our Retail Point Of Sale Software
            </Typography>
          </div>
          {/* Add any other content you want for the left side */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Slide ${index + 1}`} style={{ maxWidth: '100%' }} />
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
};

export default Imageslider;
