import { Typography, Container, Grid, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  LinkedIn,
  Instagram,
  Facebook,
  Mail,
  WhatsApp,
} from "@mui/icons-material";
import { IconButton, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import XIcon from "@mui/icons-material/X";
import Home from "../Screen/Home/Home";
import Contact from "../Screen/Contact/Contact";
import AboutUs from "../Footer/AboutUs";

const Footer = () => {
  const customStyle = {
    fontSize: "clamp(1rem, 0.1737rem + 2.1053vw, 1.2rem)",
    color: "#03989e",
    fontWeight: "550",
    marginTop: "5%",
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <>
      <footer style={{}}>
        <Container maxWidth="100%" style={{ padding: "20px 10px 0px 20px" }}>
          <Grid container spacing={0} style={{ textAlign: "left" }}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                style={{
                  fontSize: "clamp(1.8rem, 0.3737rem + 2.1053vw, 2rem)",
                  color: "#03989e",
                  fontWeight: "550",
                }}
              >
                UBS Bill
              </Typography>
              <Typography
                style={{
                  textAlign: "left",
                  fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1rem)",
                  padding: "15px 0px 0px 0px",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Add</span> - Shree Ganesh
                Building, Near, Akurdi Railway Station Rd, Pune, Maharashtra
                411033
              </Typography>
              <Box
                style={{
                  display: "flex",
                  textAlign: "left",
                  fontSize: "clamp(0.2rem, 0.0737rem + 2.1053vw, 1rem)",
                }}
              >
                <Box>
                  <Typography style={{ padding: "10px 10px 10px 15px" }}>
                    {" "}
                    <ul>
                      <li>
                        <b style={{ fontWeight: "500" }}>Our Offices</b>
                      </li>
                    </ul>{" "}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1rem)",
                    }}
                  >
                    {" "}
                    Pune / Sharjah / Dubai
                  </Typography>
                </Box>
                <Box>
                  <Typography style={{ padding: "10px 15px 10px 30px" }}>
                    {" "}
                    <ul>
                      <li>
                        <b style={{ fontWeight: "500" }}>Our Presences</b>
                      </li>
                    </ul>
                  </Typography>
                  <Typography
                    style={{
                      paddingLeft: "20px",
                      fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1rem)",
                    }}
                  >
                    {" "}
                    India / UAE / Nepal  
                  </Typography>
                </Box>
              </Box>
              <Box>
                {" "}
                <Typography
                  style={{
                    padding: "10px 0px 0px 0px",
                    fontSize: "clamp(0.8rem, 0.2737rem + 2.1053vw, 0.9rem)",
                    fontWeight:"550",
                  }}
                >
                  CIN U72900PN2022PTC20737
                </Typography>
              </Box>
            </Grid>
            <scrollToTop />

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={{ textAlign: "left", paddingLeft: "0px" }}
            >
              <Typography style={customStyle}>Quick Links</Typography>
              <Box>
                <Typography
                  style={{
                    fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1rem)",
                    padding: "10px",
                  }}
                >
                  <span>&bull; </span>
                  <NavLink
                    to="/"
                    element={<Home />}
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Home
                  </NavLink>
                  <br />
                  <span>&bull; </span>
                  <NavLink
                    to="/AboutUs"
                    element={<AboutUs />}
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    About Us
                  </NavLink>
                  <br />
                  <span>&bull; </span>
                  <NavLink
                    to="/RestaurantPOS"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Products
                  </NavLink>
                  <br />

                  <span>&bull; </span>
                  <NavLink
                    to="/Pricing"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    POS Pricing
                  </NavLink>

                  <br />
                  <span>&bull; </span>
                  <NavLink
                    to="/Contact"
                    element={<Contact />}
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Contact Us
                  </NavLink>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3} style={{ textAlign: "left" }}>
              <Typography variant="h6" style={customStyle}>
                Connect Us
              </Typography>
              <Typography
                style={{
                  fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1rem)",
                  padding: "10px",
                }}
              >
                <span>&bull; </span>
                <NavLink style={{ color: "black", textDecoration: "none" }}>
                  Testimonials
                </NavLink>
                <br />

                <span>&bull; </span>
                <NavLink
                  to="/PrivacyPolicy"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Privacy Policy
                </NavLink>
                <br />
                <span>&bull; </span>
                <NavLink
                  to="/TermsAndCondition"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Terms & Condition
                </NavLink>
                <br />
                <span>&bull; </span>
                <NavLink
                  to="/CancellationAndRefund"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Cancellation & Refund Policy
                </NavLink>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={3} style={{ textAlign: "left" }}>
              <Typography variant="h6" style={customStyle}>
                Products
              </Typography>
              <Typography
                style={{
                  fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1rem)",
                  padding: "10px",
                }}
              >
                <span>&bull; </span>
                <NavLink
                  to="/RestaurantPOS"
                  element={<Home />}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Restaurant POS
                </NavLink>
                <br />
                <span>&bull; </span>
                <NavLink
                  to="/GeneralPOS"
                  element={<Home />}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  General POS
                </NavLink>
                <br />
              </Typography>
              <Link to="/Demo">
                {" "}
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginBottom: "30px",
                    marginTop: "10px",
                    backgroundColor: "#03989e",
                  }}
                >
                  Book a Demo
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <Grid container justifyContent="flex-end" alignItems="flex-end">
          <Grid item xs={12} sm={6} md={4}>
            <div style={{}}>
              <IconButton>
                <a
                  href="https://www.linkedin.com/company/ubs-bill/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedIn style={{ color: "#000" }} />
                </a>
              </IconButton>

              <IconButton>
                <a
                  href="https://www.instagram.com/ubs_bill?igsh=MWtuN2o0c2NwOHZmNg=="
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram style={{ color: "#000" }} />
                </a>
              </IconButton>

              <IconButton>
                <a
                  href="https://www.facebook.com/share/ZjkRVafvRcwd6ApC/?mibextid=qi2Omg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook style={{ color: "#000" }} />
                </a>
              </IconButton>

              <IconButton>
                <a
                  href="https://twitter.com/ubssspl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <XIcon style={{ color: "#000", fontSize: "19px" }} />
                </a>
              </IconButton>

              <IconButton>
                <a href="mailto:info@ubsbill.com">
                  <Mail style={{ color: "#000" }} />
                </a>
              </IconButton>

              <IconButton>
                <a
                  href="https://wa.me/9112113322?text=Hello%20from%20SYNTIARO!"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsApp style={{ color: "#000" }} />
                </a>
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </footer>
      <hr style={{ border: "none", margin: "0 0" }} />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "50px",
          backgroundColor: "#03989e",
          textAlign: "center",
          padding: "5px",
          color: "white",
          fontSize: "clamp(0.6rem, 0.2737rem + 1.0053vw, 1rem)",
        }}
      >
        ALL RIGHTS RESERVED BY SYNTIARO
      </Box>
    </>
  );
};

export default Footer;
