import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  CardMedia,
  List,
  Hidden,
  Stack,
  Card,
} from "@mui/material";
import About from "../../assets/About.png";
import Laptop from "../../assets/Laptop.png";
import Idea from "../../assets/Idea.png";
import Query from "../../assets/Query.png";
import Vision from "../../assets/Vision.png";
import Mission from "../../assets/Mission.png";
import Possoftware from "../../assets/Possoftware.png";
import Tracker from "../../assets/Tracker.png";
import CRM from "../../assets/CRM.png";
import Travel from "../../assets/Travel.png";
import { Helmet } from "react-helmet";

const card = [
  {
    pic: Laptop,
    alt: "image",
    title: "POS Website Development",
    description:
      "Building seamless POS websites for efficient transactions and enhanced customer experiences.",
  },
  {
    pic: Idea,
    alt: "image",
    title: "Custom Software Solutions",
    description:
      "Tailoring software solutions to optimize operations and boost productivity for diverse business needs.",
  },
  {
    pic: Query,
    alt: "image",
    title: "E-commerce Integration",
    description:
      "Integrating e-commerce platforms with POS systems to create unified retail ecosystems for businesses.",
  },
];

const cardData = [
  { imageUrl: Possoftware, title: "Restaurant POS Software" },
  { imageUrl: Tracker, title: "Salesman Tracker" },
  { imageUrl: CRM, title: "CRM Software" },
  { imageUrl: Travel, title: "Travel Agency Software" },
];

const CircularCardContainer = ({ imageUrl }) => {
  const [isHovered, setIsHovered] = useState(false);

  const circularCardStyle = {
    position: "relative",
    backgroundColor: "#F6F9FA",
    width: 100,
    height: 100,
    margin: "20px",
    boxShadow: "none",
    transition: "transform 0.3s",
    transform: isHovered ? "scale(1.2)" : "scale(1)",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <Card
      style={circularCardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={imageUrl} alt="icon" style={imageStyle} />
    </Card>
  );
};

function AboutUs() {
  return (
    <>
    <Helmet>
        <title>AboutUs| UBS Bill</title>
      </Helmet>
    <div style={{ marginTop: "2%" }}>
      <img src={About} alt="About" style={{ width: "100%" }} />
      <Box style={{ marginTop: "2%" }}>
        <Typography
          style={{
            fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 2rem)",
            fontWeight: "500",
            padding: "10px",
          }}
        >
          What We Do?
        </Typography>

        <Typography
          style={{
            fontSize: "clamp(0.2rem, 0.3737rem + 2.053vw, 1rem)",
            textAlign: "left",
            padding: "10px 40px 10px 40px ",
            color: "#5C6574",
          }}
        >
          UBS Bill is a IT Solution base company looking to provide a more
          personal touch to an increasingly less personal industry. We aim to
          provide you with all the information you need and assist you with each
          step in the process. It provides IT Solutions with Advanced
          Technology.We develop solutions that are meant to enhance productivity
          and profitability by focusing on crucial business functions as well as
          auxiliary ones. We improve the functioning of elements such as sound
          communication and coordination between all departments, enterprises,
          travel agency, retail business, inventory management, restaurants
          management, and customer relationship management.
        </Typography>
      </Box>

      <Grid
        container
        justifyContent={"space-around"}
        textAlign={"center"}
        sx={{ paddingY: "40px", marginY: "3rem" }}
      >
        {card.map((title1, index) => (
          <Box
            key={index}
            margin={"1rem"}
            padding={"2rem 1rem"}
            sx={{
              backgroundColor: "white",
              borderRadius: "20px",
              width: { xs: "45%", md: "30%", sm: "45%" },
              marginX: "auto",
              marginBottom: "2rem",
              transition: "transform 0.3s",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
            height={"auto"}
          >
            <CardMedia
              component={"img"}
              sx={{
                objectFit: "cover",
                height: "15vmin",
                width: "15vmin",
                margin: "auto",
              }}
              title=""
              image={title1.pic}
            />
            <Typography
              variant="h1"
              fontSize={"clamp(1rem, 0.2737rem + 2.1053vw, 1.5rem)"}
              fontWeight={"550"}
              margin={"1rem 0"}
            >
              {title1.title}
            </Typography>
            <Typography fontSize={"clamp(0.2rem, 0.2737rem + 2.053vw, 1rem)"}>
              {title1.description}
            </Typography>
          </Box>
        ))}
      </Grid>

      <Box sx={{ p: "20px", textAlign: "left" }}>
        <Typography
          style={{
            fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
            fontWeight: "500",
            padding: "7px",
            textAlign: "start",
          }}
        >
          Why Choose Us?
        </Typography>
        <br />
        <Typography
          sx={{
            fontSize: "clamp(0.8rem, 0.3737rem + 2.1053vw, 1.2rem)",
            paddingLeft: "40px",
          }}
        >
          Because we believe in providing Quality not just the first time, but
          every time :
          <br />
          <List sx={{ paddingLeft: "20px" }}>
            <ul style={{ listStyleType: "disc" }}>
              <li> Free Online Demo</li>
              <li>High Customer satisfaction</li>
              <li>Quality Project Delivery</li>
              <li>Transparent Development Process</li>
            </ul>
          </List>
        </Typography>
      </Box>

      {/* grid1 */}
      <Grid style={{ backgroundColor: "#F6F9FA" }}>
        <Grid container spacing={2} style={{ marginTop: "2%" }}>
          <Grid item xs={12} md={6} paddingLeft={"60px"}>
            <CardMedia
              component="img"
              alt="Image"
              style={{
                width: "70%",
                height: "70%",
                padding: "0px 10px 0px 110px",
              }}
              image={Vision}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              component="div"
              style={{
                color: "#000",
                fontWeight: "500",
                fontSize: "3.5vmin",
                textAlign: "left",
                marginLeft: "40px",
              }}
            >
              Our Vision
            </Typography>{" "}
            <br />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                style={{
                  fontSize: "clamp(0.2rem, 0.3737rem + 2.053vw, 1rem)",
                  textAlign: "left",
                  padding: "10px 40px 10px 40px ",
                  color: "#5C6574",
                }}
              >
                At UBS Bill, we're dedicated to pioneering innovative software
                solutions that empower businesses across various sectors. We aim
                to be the trusted partner for growth, enhancing efficiency and
                customer satisfaction through cutting-edge technology. Our
                vision is to lead in software innovation, serving industries
                like restaurants, retail, education, and more with tailored
                solutions that elevate operations.{" "}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* grid2 */}
        <Grid container spacing={2} style={{ marginTop: "2%" }}>
          <Hidden mdUp>
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                alt="Image"
                style={{
                  width: "70%",
                  height: "70%",
                  padding: "10px 10px 0px 110px",
                }}
                image={Mission}
              />
            </Grid>
          </Hidden>

          <Grid item xs={12} md={6}>
            <Typography
              component="div"
              style={{
                color: "#000",
                fontWeight: "500",
                fontSize: "3.5vmin",
                textAlign: "left",
                marginLeft: "40px",
              }}
            >
              Our Mission
            </Typography>
            <br />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                style={{
                  fontSize: "clamp(0.2rem, 0.3737rem + 2.053vw, 1rem)",
                  textAlign: "left",
                  padding: "10px 40px 10px 40px ",
                  color: "#5C6574",
                }}
              >
                Our mission is to create advanced POS software tailored for
                restaurants, garment stores, supermarkets, schools, salons, and
                sales teams. We're committed to simplifying operations,
                enhancing customer experiences, and fostering growth in each
                sector. From revolutionizing dining experiences to streamlining
                sales team performance, we strive to be the cornerstone of
                efficiency and innovation for businesses.{" "}
              </Typography>
            </Box>
          </Grid>

          <Hidden mdDown>
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                alt="Image"
                style={{
                  width: "70%",
                  height: "70%",
                  padding: "10px 10px 0px 110px",
                }}
                image={Mission}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      <div
        style={{
          backgroundColor: "#F6F9FA",
          textAlign: "center",
          marginTop: "15px",
        }}
      >
        <div style={{ padding: "20px 0px 50px",marginTop:"3%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {cardData.map((data, index) => (
                <Stack key={index} alignItems={"center"}>
                  <CircularCardContainer imageUrl={data.imageUrl} />
                  <Typography
                    style={{
                      fontSize: "clamp(0.8rem, 0.2737rem + 1.0053vw, 1.5rem)",
                    }}
                  >
                    {data.title}
                  </Typography>
                </Stack>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AboutUs;
