import Pagenotfound from "../../Forms/Pagenotfound"
import { Helmet } from "react-helmet";

const UBScart = () => {
  return (
    <div>
       <Helmet>
        <title>UBSCart | UBS Bill</title>
      </Helmet>
     <Pagenotfound/>
    </div>
  )
}

export default UBScart
