import React, { useState } from "react";
import { Box, Button, Grid, Typography, TextField, CircularProgress, InputLabel } from "@mui/material";
import CustomSnackbar from "./Customsnackbar";
import axios from "axios";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneno: "",
    city: "",
    state: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phoneno: "",
    city: "",
    state: "",
  });

  const [loading, setLoading] = useState(false); 

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    let errorMessage = "";

    switch (field) {
      case "phoneno":
        value = value.replace(/\D/g, "").slice(0, 10);
        errorMessage = !/^[6-9]\d{9}$/.test(value)
          ? "Please enter a valid 10-digit number"
          : "";
        break;

      case "email":
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        errorMessage = !emailRegex.test(value)
          ? "Please enter a valid email address"
          : "";
        break;
      default:
        value = value.replace(/[^a-zA-Z ]/g, "");
        break;
    }

    setErrors({ ...errors, [field]: errorMessage });
    setFormData({ ...formData, [field]: value });
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
    .post(
      "https://api.ubsbill.com/api/request-for-enquiry-Form/register",
      formData
    )
    .then((response) => {
      console.log("form submitted", response.data);
      setSuccessSnackbarOpen(true);
      setFormData({
        name: "",
        email: "",
        phoneno: "",
        city: "",
        state: "",
      });
      setSubmitting(false);
    })
    .catch((error) => {
      console.error("Error posting data:", error);
      setOpenSnackbar(true); 
      setSubmitting(false);
    });
    setSubmitting(true);
    const requiredFields = ["name", "email", "phoneno", "city", "state"];
    const emptyFields = [];
    for (const field of requiredFields) {
      const fieldValue =
        typeof formData[field] === "string"
          ? formData[field].trim()
          : formData[field];
      if (!fieldValue) {
        emptyFields.push(field);
      }
    }
    if (emptyFields.length > 0) {
      setSnackbarMessage(
        `Please fill in the following fields: ${emptyFields.join(", ")}`
      );
      setOpenSnackbar(true);
      return;
    }
    if (errors.name || errors.email || errors.phoneno || errors.city || errors.state) {
      setSnackbarMessage("Please fix validation errors");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

   
    setTimeout(() => {
      console.log(formData);
      setSuccessSnackbarOpen(true);
      setLoading(false); 
      setFormData({
        name: "",
        email: "",
        phoneno: "",
        city: "",
        state: "",
      });
    }, 2000);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setSuccessSnackbarOpen(false);
  };

  

  return (
    <div>
      <Grid>
        <Box
          sx={{
            width: "100%",
            padding: "5px",
          }}
        >
          <Typography
            fontWeight={"bold"}
            style={{ textAlign: "start", fontSize: "clamp(0.9rem, 0.8737rem + 2.1053vw, 2rem)" }}
          >
            Contact Us
          </Typography>
          <Typography
            style={{
              textAlign: "start",
              padding: "5px",
              fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.2rem)",
              color: "#5C6574",
            }}
          >
            Feel free to contact us anytime. We will get back to you as soon as possible
          </Typography>
          <Box mt={3} fontSize={"1.2rem"} padding={"5px 5px 5px 25px"}>
            <Grid
              container
              rowGap={2}
              columnSpacing={2}
              justifyContent={"center"}
              textAlign={"start"}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="name">Name</InputLabel>
                  <TextField
                    name="name"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange("name")}
                    size="small"
                    placeholder="Name"
                    inputProps={{
                      minLength: 3,
                      maxLength: 50,
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange("email")}
                    size="small"
                    InputProps={{
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    inputProps={{
                      minLength: 10,
                      maxLength: 50,
                    }}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} sx={{ marginTop: "2px" }}>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="phoneno">Phone No</InputLabel>
                  <TextField
                    fullWidth
                    name="phoneno"
                    value={formData.phoneno}
                    onChange={handleChange("phoneno")}
                    size="small"
                    placeholder="Phone No"
                    InputProps={{
                      style: {
                        backgroundColor: "white",
                        borderRadius: "5px",
                      },
                    }}
                    inputProps={{
                      minLength: 10,
                      maxLength: 10,
                    }}
                    error={!!errors.phoneno}
                    helperText={errors.phoneno}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="city">City</InputLabel>
                  <TextField
                    name="city"
                    fullWidth
                    value={formData.city}
                    onChange={handleChange("city")}
                    size="small"
                    placeholder="City"
                    inputProps={{
                      minLength: 3,
                      maxLength: 50,
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ marginTop: "2px" }}>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="state">State</InputLabel>
                  <TextField
                    fullWidth
                    name="state"
                    value={formData.state}
                    onChange={handleChange("state")}
                    size="small"
                    placeholder="state"
                    InputProps={{
                      style: {
                        backgroundColor: "white",
                        borderRadius: "5px",
                      },
                    }}
                    inputProps={{
                      minLength: 10,
                      maxLength: 10,
                    }}
                    error={!!errors.state}
                    helperText={errors.state}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start">
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Button
                    sx={{
                      backgroundColor: "#00989E",
                      width: "10vw",
                      fontSize: "0.8rem",
                      color: "white",
                      padding: "10px 20px",
                      marginTop: "12px",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                      "&:hover": {
                        backgroundColor: "#d8eded",
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    SUBMIT
                  </Button>
                )}
              </Grid>

              <CustomSnackbar
                open={openSnackbar}
                autoHideDuration={3000}
                handleClose={handleCloseSnackbar}
                message={snackbarMessage}
                severity="error"
              />
              <CustomSnackbar
                open={successSnackbarOpen}
                autoHideDuration={3000}
                handleClose={handleCloseSnackbar}
                message="Form Submitted Successfully!"
                severity="success"
              />
            </Grid>
          </Box>
        </Box>
      </Grid>
    </div>
  );
}

export default ContactUs;
