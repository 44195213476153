import { Box, Typography, Card, CardMedia, Grid } from "@mui/material";
import image1 from "..//..//..//..//assets/os1.png";
import image2 from "..//..//..//..//assets/os2.png";
import image3 from "..//..//..//..//assets/os3.png";
import image4 from "..//..//..//..//assets/os4.png";

function RestaurantOS() {
  const cards = [
    {
      image: image1,
      title: "Owner App with Dashboard",
    },
    {
      image: image2,
      title: "Desktop and Mobile POS with Cloud Sync",
    },
    {
      image: image3,
      title: "Inventory and Stock Management",
    },
    {
      image: image4,
      title: "24 X 7 Support",
    },
  ];

  return (
    <>
      <Box>
        <Typography
          sx={{ fontSize: "clamp(0.5rem, 0.5737rem + 2.1053vw, 1.8rem)", fontWeight: "bold", textAlign: "center",padding:"10px 20px 0px 20px",marginTop:"1%" }}
        >
          Restaurant Operating System
        </Typography>
        <Typography
          style={{ fontSize: "clamp(0.2rem, 0.2737rem + 2.053vw, 1rem)", textAlign: "left",padding:"10px 30px 10px 30px ", color: "#5C6574", }}
        >
          Dedicated to the managers and hotel owners, the managerial reports
          gives you a quick glance on the business current status along with
          detailed analysis about total income, income source expenses and more
          helping to analyze profiting sources of income along with insights to
          guest moment and guest behavior
        </Typography>
      </Box>
      <Grid container spacing={2} padding={"2% 15% 5% 15%"}>
        {cards.map((card, index) => (
          <Grid
            item
            key={index}
            xs={6}
            sm={6}
            md={3}
            lg={3}
            alignItems={"center"}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Card
                sx={{
                  height: "20vmin",
                  width: "20vmin",
                  display: "flex",
                  borderRadius: "50%",
                  flexDirection: "column",
                  alignItems: "center",
                  background: index % 2 === 0 ? "#F2AAA2" : "#F9DC63",
                  transition: "transform 0.3s ease", 
                }}
                onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={card.image}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Card>

              <Typography
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  marginTop: "7%",
                  fontSize: "clamp(0.2rem, 0.1737rem + 2.053vw, 1rem)",
                  textAlign: "center",
                }}
              >
                {card.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default RestaurantOS;
