import { Box, Grid, Typography, Hidden, List } from "@mui/material";
import Downpic from "../../assets/Downpic.png";
import Image1 from "../../assets/Image1.png";
import Image2 from "../../assets/Image2.png";
import Image3 from "../../assets/Image3.png";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  return (
    <div>
        <Helmet>
        <title>Privacy Policy | UBS Bill</title>
      </Helmet>
      <Box
        position="relative"
        style={{ height: "100%", display: "flex", alignItems: "flex-end" }}
      >
      <Hidden smDown>
      <img src={Downpic} alt="logo" style={{ width: "100%" }} />
      </Hidden>
      <Hidden smUp>
      <img src={Downpic} alt="logo" style={{ width: "100%" ,paddingTop:"4.3rem"}} />
      </Hidden>
        <Box
          position="absolute"
          bottom="10px"
          left="10px"
          color="white"
          padding="10px"
          borderRadius="5px"
        >
          <Typography style={{ margin: 0, fontSize: "3.5vmin" }}>
            Privacy Policy
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
            marginTop: "3%",
          }}
        >
          LAST UPDATED:APRIL 20,2022
        </Typography>
        <Typography
          sx={{
            p: "5px 20px 10px 20px",
            fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
            color: "#5C6574",

            marginTop: "2%",
            textAlign: "left",
          }}
        >
          Thanks for visiting this page! Your privacy is very important to us
          and we want to confirm that your information is safe with us. In this
          Policy we will explain our commitment to protecting your personal data
          and the choices you have with respect to the information. If you have
          any questions about our Privacy Policy, please contact us at
          info@ubsbill.com
        </Typography>
      </Box>

      {/* 1 */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <img
            src={Image1}
            alt="privacy"
            style={{
              width: "60%",
              height: "60%",
              marginTop: "55px",
              marginLeft: "15px",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          mt={5}
          style={{ padding: "0px 40px 0px 40px" }}
        >
          <Box sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
              }}
            >
              Personal Information
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
                color: "#5C6574",
              }}
            >
              Our privacy policy applies to syntiaro.com and other Syntiaro
              websites , our products and services, and any communications you
              have with us (eg. customer service inquiries). If you do not agree
              with our policy, please do not send us personal information. In
              the context of this policy, “personal information” and “personal
              data” have the same meaning. They refer to information relating to
              an identified or identifiable natural person. An identifiable
              person is one who can be identified, directly or indirectly, in
              particular by reference to an identifier such as a name, or
              identification number.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* 2 */}
      <Grid container spacing={3}>
        <Hidden smUp>
          <Grid item xs={12} sm={6}>
            <img
              src={Image2}
              alt="privacy"
              style={{
                width: "70%",
                height: "70%",
                marginTop: "20px",
                marginLeft: "15px",
              }}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6} style={{ padding: "0px 40px 0px 40px" }}>
          <Box sx={{ p: "10px", textAlign: "left" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
              }}
            >
              Information We Collect
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
                color: "#5C6574",
              }}
            >
              When you use our websites and services, you provide us with the
              following information which we store securely:
              <br />
              <List sx={{ marginLeft: "25px" }}>
                <ul style={{ listStyleType: "disc" }}>
                  <li>Account information such as username and password</li>
                  <li>
                    Contact information such as name, email address, billing
                    address, phone number and country
                  </li>
                  <li>
                     Information about the products and services you sell
                    (including inventory, pricing, sale, transaction, tax, and
                    other data)
                  </li>
                  <li>
                     Information about your business (number of stores, or
                    staff)
                  </li>
                </ul>
              </List>
            </Typography>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={6}>
            <img
              src={Image2}
              alt="privacy"
              style={{
                width: "70%",
                height: "70%",
                marginTop: "20px",
                marginLeft: "15px",
              }}
            />
          </Grid>
        </Hidden>
      </Grid>
      {/* 3 */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <img
            src={Image3}
            alt="privacy"
            style={{
              width: "70%",
              height: "70%",
              marginTop: "15px",
              marginLeft: "15px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: "0px 20px 0px 40px" }}>
          <Box sx={{ p: "10px", textAlign: "left" }}>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
              }}
            >
              Security
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
                color: "#5C6574",
              }}
            >
              We take data security very seriously. In order to prevent
              unauthorised access or disclosure, we have put in place technical
              and managerial procedures to safeguard and secure the information
              we collect online. This includes secure connections for our
              administration system. We also implement other security and access
              controls, including username and password authentication and data
              encryption where appropriate. Only our authorized employees have
              access to your personal data.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PrivacyPolicy;
