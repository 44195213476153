import React from "react";
import Outlet from "./Subcompo/Outlate";
import Demo from "../../Forms/Demo";
import Restaurantpos from "./Subcompo/Resturantpos";
import Header from "./Subcompo/header";
import AboutPOS from "./Subcompo/AboutPOS";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title> UBS Bill</title>
      </Helmet>
      <Header />
      <AboutPOS />
      <Outlet />
      <Restaurantpos />
      <div style={{ marginTop: "-5%" }}>
        <Demo />
      </div>
    </div>
  );
};

export default Home;
