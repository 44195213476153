import React, { useState } from "react";
import { Box, Card, Typography, Grid, Container, Button } from "@mui/material";
import { Link } from "react-router-dom";
const Restaurantplans = () => {
  const [basicHovered, setBasicHovered] = useState(false);
  const [advanceHovered, setAdvanceHovered] = useState(false);
  const [premiumHovered, setPremiumHovered] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleButtonClick = (price) => {
    localStorage.setItem("selectedPrice", price);
  };
  
  return (
    
    <div style={{ marginTop: "5%" }}>
      <Typography
        style={{
          fontWeight: "bold",
          marginBottom: "15px",
          textAlign: "center",
          fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 2rem)",
        }}
      >
        Choose The Best Plan that Suits You
      </Typography>
      <Box sx={{ backgroundColor: "#F6F9FA", p: 2 }}>
        <Typography
          style={{
            fontWeight: "bold",
            marginBottom: "15px",
            textAlign: "center",
            fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.8rem)",
          }}
        >
          Restaurant POS Package
        </Typography>
        <div
          style={{
            padding: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Button
              component="a"
              style={{
                color: "#000",
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.5rem)",
                backgroundColor: basicHovered ? "#A1DEEA" : "#fff",
                padding: "10px",
                borderRadius: "5px",
                width: "35%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                textAlign: "center",
              }}
              onMouseEnter={() => setBasicHovered(true)}
              onMouseLeave={() => setBasicHovered(false)}
            >
              Basic
            </Button>
            <Button
              component="a"
              style={{
                color: "#000",
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.5rem)",
                marginLeft: "50px",
                backgroundColor: advanceHovered ? "#90ee90" : "#fff",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                width: "45%",
                textAlign: "center",
              }}
              onMouseEnter={() => setAdvanceHovered(true)}
              onMouseLeave={() => setAdvanceHovered(false)}
            >
              Advance
            </Button>
            <Button
              component="a"
              style={{
                color: "#000",
                fontWeight: "bold",
                marginLeft: "50px",
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.5rem)",
                backgroundColor: premiumHovered ? "#ff8080" : "#fff",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                width: "45%",
                textAlign: "center",
              }}
              onMouseEnter={() => setPremiumHovered(true)}
              onMouseLeave={() => setPremiumHovered(false)}
            >
              Premium
            </Button>
          </div>
        </div>
      </Box>

      <Container component="main" maxWidth="md" sx={{ mt: 3 }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
          style={{ minHeight: "20vh" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Card
              style={{
                height: "200px",
                background: "#fff",
                borderRadius: "0px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: advanceHovered
                    ? "#90ee90"
                    : premiumHovered
                    ? "#ff8080"
                    : "#A1DEEA",
                  height: "25%",
                  padding: "10px",
                  fontWeight: "bold",
                  width: "100%",
                  marginTop: "6px",
                  textAlign: "center",
                }}
              >
                1 Year Pack{" "}
              </Typography>

              {!(advanceHovered || premiumHovered) && (
                <Box
                  style={{
                    height: "40%",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="span"
                    style={{ fontWeight: "bold" }}
                  >
                    11,999
                  </Typography>
                  <Typography variant="h6" component="span">
                    With GST
                  </Typography>{" "}
                  <br />
                </Box>
              )}
              <Box style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#000", color: "#fff" }}
                  onClick={() => handleButtonClick("11999")} 
                >
                  <Link
                    to="/checkout"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Get Started
                  </Link>
                </Button>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              style={{
                height: "200px",
                background: "#fff",
                borderRadius: "0px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: advanceHovered
                    ? "#90ee90"
                    : premiumHovered
                    ? "#ff8080"
                    : "#A1DEEA",
                  height: "25%",
                  padding: "10px",
                  fontWeight: "bold",
                  width: "100%",
                  marginTop: "6px",
                  textAlign: "center",
                }}
              >
                2 Year Combo Pack{" "}
              </Typography>
              {!(advanceHovered || premiumHovered) && (
                <Box
                  style={{
                    height: "40%",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="span"
                    style={{ fontWeight: "bold" }}
                  >
                    17,999{" "}
                  </Typography>
                  <Typography variant="h6" component="span">
                    With GST
                  </Typography>{" "}
                  <br />
                </Box>
              )}
              <Box style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#000", color: "#fff" }}
                  onClick={() => handleButtonClick("17999")} 
                >
                  <Link
                    to="/checkout"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Get Started
                  </Link>
                </Button>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              style={{
                height: "200px",
                background: "#fff",
                borderRadius: "0px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: advanceHovered
                    ? "#90ee90"
                    : premiumHovered
                    ? "#ff8080"
                    : "#A1DEEA",
                  height: "25%",
                  padding: "10px",
                  fontWeight: "bold",
                  width: "100%",
                  marginTop: "6px",
                  textAlign: "center",
                }}
              >
                Yearly Renewal
              </Typography>
              {!(advanceHovered || premiumHovered) && (
                <Box
                  style={{
                    height: "40%",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="span"
                    style={{ fontWeight: "bold" }}
                  >
                    7,999{" "}
                  </Typography>
                  <Typography variant="h6" component="span">
                    With GST
                  </Typography>{" "}
                  <br />
                </Box>
              )}
              <Box style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#000", color: "#fff" }}
                  onClick={() => handleButtonClick("7999")} 
                >
                  <Link
                    to="/checkout"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Get Started
                  </Link>
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Restaurantplans;
