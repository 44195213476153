import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import React from "react";
import image1 from "../../../../assets/P1.png";
import image2 from "../../../../assets/P2.png";
import image3 from "../../../../assets/P3.png";
import image4 from "../../../../assets/P4.png";

function Cards() {
  const cards = [
    {
      image: image1,
      title: "Gain Full Control",
      description: "Manage every aspect of your operations effortlessly.",
    },
    {
      image: image2,
      title: "Dedicated Support",
      description: "We guide and support you along your journey of loss",
    },
    {
      image: image3,
      title: "Priority Support",
      description:
        "Immediate assistance and personalized solutions tailored to your needs.",
    },
    {
      image: image4,
      title: "Efficient Solutions",
      description:
        "Simplify transactions with tailored support for your business needs.",
    },
  ];

  return (
    <>
      <Box padding={"10px 30px 20px 30px"}>
        <Typography
          sx={{
            fontSize: "clamp(1.2rem, 0.4737rem + 2.1053vw, 2rem)",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "5%",
          }}
        >
          What do you get by subscribing paid plan
        </Typography>
        <Typography
          sx={{
            fontSize: "clamp(0.2rem, 0.3737rem + 2.1053vw, 1.2rem)",
            textAlign: "center",
            paddingTop: "10px",
          }}
        >
          We are small , but growing team Our expertise includes growth ,
          marketing , design , software development, and product management
        </Typography>
      </Box>
      <Grid container spacing={2} padding={"5% 10% 0% 10%"}>
        {cards.map((card, index) => (
          <Grid
            item
            key={index}
            xs={6}
            sm={6}
            md={3}
            lg={3}
            alignItems={"space-between"}
          >
            <Card
              sx={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                background: index % 2 === 0 ? "#A9E0E2" : "#E4F6F6",
                marginTop: index % 2 === 0 ? "0%" : "-15%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <CardMedia
                component="img"
                image={card.image}
                sx={{
                  maxHeight: "7vmin",
                  maxWidth: "7vmin",
                  margin: "auto",
                  marginTop: "5%",
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    marginTop: "2%",
                    fontSize: "clamp(1rem, 0.4737rem + 2.1053vw, 0.8rem)",
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  sx={{
                    fontSize: "clamp(0.4rem, 0.1737rem + 2.1053vw, 1.1rem)",
                  }}
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Cards;
