import React from "react";
import { useState } from "react";
import {Grid,
  CardMedia,
  Typography,
  Box,
  Button,
  Hidden,

} from "@mui/material";
import image1 from "../../../../assets/Rectangle 270.jpg";
import image2 from "../../../../assets/Rectangle 268.jpg";

const AboutPOS = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100%",
  };

  return (
    <div>
     
      {/* grid1 */}
      <Grid style={{ backgroundColor: "#F6F9FA", padding: "20px" }}>
        <Grid container spacing={2} style={{ marginTop: "2%" }}>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              alt=" Image"
              style={{
                width: "100%",
                height: "70%",
                padding: "10px 10px 0px 10px",
              }}
              image={image2}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              component="div"
              style={{
                color: "#000",
                fontWeight: "500",
                fontSize: "clamp(0.8rem, 0.4737rem + 2.1053vw, 1.6rem)",
                textAlign: "left",
              }}
            >
              Restaurant POS software
            </Typography>{" "}
            <br />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                style={{
                  fontSize: "clamp(0.5rem, 0.2737rem + 2.1053vw, 1.1rem)",
                  textAlign: "start",

                  marginTop: "-10px",
                  color: "#5C6574",
                }}
              >
                Restaurant POS software streamlines cash flow, inventory,
                franchise operations, staff management, and data security. It
                ensures financial stability, prevents stock issues, maintains
                brand consistency, optimizes productivity, and safeguards
                sensitive information, ensuring smooth restaurant operations.
              </Typography>
              <Box
                style={{
                  textAlign: "start",
                  marginTop: "20px",
                }}
              >
                <Box
                  className="box"
                  style={{
                    width: "auto",
                  }}
                >
                  <a href="/RestaurantPOS">
                    <Button
                      className="button13"
                      style={{
                        color: "white",
                        backgroundColor: "#00989E",
                        fontWeight: "bold",
                        fontSize: "2vmin",
                        width: "auto",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#d8eded",
                        },
                      }}
                    >
                      Know More
                    </Button>
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* grid2 */}
        <Grid container spacing={2} style={{ marginTop: "0%" }}>
          <Hidden mdUp>
            <Grid item xs={12} md={6} style={{ padding: "10px 10px 0px 10px" }}>
              <CardMedia
                component="img"
                alt=" Image"
                style={{
                  width: "100%",
                  height: "70%",
                  padding: "10px 10px 0px 10px",
                }}
                image={image1}
              />
            </Grid>
          </Hidden>

          <Grid item xs={12} md={6}>
            <Typography
              component="div"
              style={{
                color: "#000",
                fontWeight: "500",
                fontSize: "clamp(0.8rem, 0.4737rem + 2.1053vw, 1.6rem)",
                textAlign: "left",
              }}
            >
              General POS software
            </Typography>
            <br />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                style={{
                  fontSize: "clamp(0.5rem, 0.2737rem + 2.1053vw, 1.1rem)",
                  textAlign: "start",

                  marginTop: "-10px",
                  color: "#5C6574",
                }}
              >
                In a general store setting, the POS system acts as a central
                hub, efficiently managing sales transactions, optimizing
                inventory levels, handling various payment methods, organizing
                customer data for personalized service, coordinating with
                suppliers for timely restocking, and implementing e-billing for
                seamless financial operations. This integrated approach ensures
                smooth day-to-day operations and enhances overall business
                performance.
              </Typography>
              <Box
                style={{
                  textAlign: "start",
                  marginTop: "20px",
                }}
              >
                <Box
                  className="box"
                  style={{
                    width: "auto",
                  }}
                >
                 <a href="/GeneralPOS">
                    <Button
                      className="button13"
                      style={{
                        color: "white",
                        backgroundColor: "#00989E",
                        fontWeight: "bold",
                        fontSize: "2vmin",
                        width: "auto",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#d8eded",
                        },
                      }}
                    >
                      Know More
                    </Button>
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Hidden mdDown>
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                alt="Your Image"
                style={{
                  width: "100%",
                  height: "70%",
                  padding: "10px 10px 0px 10px",
                }}
                image={image1}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutPOS;
