import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Screen/Home/Home";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Pricing from '../Screen/Pricing/Pricing'
import UBScart from '../Screen/UBScart/UBScart'
import RestaurantPOS from "../Screen/RestaurantPOS/RestaurantPOS";
import GeneralPOS from "../Screen/GeneralPOS/GeneralPOS";
import PrivacyPolicy from "../Footer/PrivacyPolicy";
import TermsAndCondition from "../Footer/TermsAndCondition";
import CancellationAndRefund from "../Footer/CancellationAndRefund";
import Restaurantplans from "../Screen/Pricing/Subcompo/Restaurantplans";
import Retailplans from "../Screen/Pricing/Subcompo/Retailplans";
import Demo from "../Forms/Demo";
import Contact from "../Screen/Contact/Contact";
import AboutUs from "../Footer/AboutUs";
import Checkout from "../Forms/Checkout";




function Routers() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/RestaurantPOS" element={<RestaurantPOS/>} />
        <Route path="/GeneralPOS" element={<GeneralPOS/>} />
        <Route path="/Pricing" element={<Pricing/>} />
        <Route path="/UBScart" element={<UBScart/>} />
        <Route path="/Contact" element={<Contact/>} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
        <Route path="/TermsAndCondition" element={<TermsAndCondition/>} />
        <Route path="/CancellationAndRefund" element={<CancellationAndRefund/>} />
        <Route path="/Restaurantplans" element={<Restaurantplans/>} />
        <Route path="/Retailplans" element={<Retailplans/>} />
        <Route path="/Demo" element={<Demo/>} />
        <Route path="/AboutUs" element={<AboutUs/>} />
        <Route path="/Checkout" element={<Checkout/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default Routers;
