import { Box, Grid, Typography, Hidden } from "@mui/material";
import Header2 from "../../assets/Header2.png";
import Image8 from "../../assets/Image8.png";
import Image9 from "../../assets/Image9.png";
import { Helmet } from "react-helmet";

const CancellationAndRefund = () => {
  return (
    <div>
      <Helmet>
        <title>CancellationAndRefund | UBS Bill</title>
      </Helmet>
      <Box
        position="relative"
        style={{ height: "100%", display: "flex", alignItems: "flex-end" }}
      >
        <Hidden smDown>
          {" "}
          <img src={Header2} alt="logo" style={{ width: "100%" }} />
        </Hidden>
        <Hidden smUp>
          {" "}
          <img
            src={Header2}
            alt="logo"
            style={{ width: "100%", paddingTop: "4.4rem" }}
          />
        </Hidden>
        <Box
          position="absolute"
          bottom="10px"
          left="10px"
          color="white"
          padding="10px"
          borderRadius="5px"
        >
          <Typography style={{ margin: 0, fontSize: "3.5vmin" }}>
            Cancellation & Refund
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
            marginTop: "3%",
          }}
        >
          Cancellation & Refund Policy
        </Typography>
        <Typography
          sx={{
            p: "10px 30px 10px 30px",
            fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
            textAlign: "left",
            color: "#5C6574",
          }}
        >
          Thanks for visiting this page! Your privacy is very important to us
          and we want to confirm that your information is safe with us. In this
          policy we will explain our commitment to protecting your personal data
          and the choices you have with respect to the information. If you have
          any questions about our Privacy Policy, please contact us at
          info@ubsbill.com
        </Typography>
      </Box>

      {/* 1 */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <img
            src={Image8}
            alt="image"
            style={{
              width: "65%",
              height: "65%",
              marginTop: "55px",
              marginLeft: "15px",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          mt={5}
          style={{ padding: "0px 40px 0px 40px" }}
        >
          <Box sx={{ p: "10px", textAlign: "left" }}>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
              }}
            >
              Introduction
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
                color: "#5C6574",
              }}
            >
              We, UBSBill Pvt Ltd ("We", "Us", "Our", “Company”), is in the
              business of providing a point of sales software application under
              the brand name UBSBill for restaurants together with optionally
              integrated services such as cash management, inventory management,
              taxes and discounts, ordering and billing, table reservation,
              suppliers’ marketplace, analytics, etc. using a programmable API
              architecture. The Company through such point of sale software
              application and websites also provides end to end integration of
              services for restaurant management to various restaurants and food
              outlets. The Company has created and owns the platforms and
              licensed the use of the same by way of various subscription model
              for a Subscription Fee.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* 2 */}
      <Grid container spacing={3}>
        <Hidden smUp>
          <Grid item xs={12} sm={6}>
            <img
              src={Image9}
              alt="image"
              style={{
                width: "70%",
                height: "70%",
                marginTop: "20px",
                marginLeft: "15px",
              }}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6} style={{ padding: "0px 40px 0px 40px" }}>
          <Box sx={{ p: "10px", textAlign: "left" }}>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                fontSize: "clamp(0.2rem, 0.4737rem + 2.1053vw, 1.8rem)",
              }}
            >
              Return and Refund Policy
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "clamp(0.2rem, 0.2737rem + 2.1053vw, 1.1rem)",
                color: "#5C6574",
              }}
            >
              Our return/refund policy is a statement explaining that your
              business will not provide compensation for purchased products or
              services that customers return. Refund rules also differ depending
              on the types of products or services you offer. No refund policies
              are allowed for products and services that are difficult to return
              or non-returnable, such as subscriptions and digital products. No
              returns will be offered once a Application filled successfully in
              customer required scheme. Fees paid are non-refundable, regardless
              of the utilization period by the Customer. Customers will pay the
              Fees through an accepted payment method as specified on the
              Website. During the Term, the Customer may not reduce their
              Service Plan or User count.
            </Typography>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={6}>
            <img
              src={Image9}
              alt="image"
              style={{
                width: "70%",
                height: "70%",
                marginTop: "20px",
                marginLeft: "15px",
              }}
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default CancellationAndRefund;
